import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { icons } from '@danfoss/etui-core';
import { Alarm } from '@danfoss/etui-sm-xml';
import useSWR from 'swr';
import { useXmlResource, useAuth, useUnit } from '@danfoss/etui-sm/context';
import { routePaths } from 'routePaths';
import { getIsEqual } from 'utils';
import { DeviceInfoColumn, ValueColumn } from 'components/EquipmentTable';
import { fetchActiveAlarmsByUnits } from '../../actions';
import { BaseTile } from './BaseTile';

export interface AlarmsTileProps {
  height: number;
  isMaximized: boolean;
  rowCount: number;
  isWidget?: boolean;
  onMaximize: () => void;
  onMinimize: () => void;
}

const AlarmsTile = React.memo(function AlarmsTile({
  height,
  isMaximized,
  rowCount,
  isWidget = false,
  onMaximize,
  onMinimize,
}: AlarmsTileProps) {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { push } = useHistory();
  const { url: xmlBackendURL } = useXmlResource();
  const { units } = useUnit();

  const skipSessionUpdate = true;
  const { data, error, isValidating, mutate } = useSWR(
    ['active alarms', xmlBackendURL, user, units, skipSessionUpdate],
    ([_key, ...args]) => fetchActiveAlarmsByUnits(args),
    {
      refreshInterval: 15000,
      shouldRetryOnError: false,
    },
  );

  const handleMutate = async () => {
    const newData = await fetchActiveAlarmsByUnits([
      xmlBackendURL,
      user,
      units,
      !skipSessionUpdate,
    ]);
    await mutate(newData, {
      revalidate: false,
    });
  };

  const handleOnAlarmClick = () => {
    push({ pathname: routePaths.pages.alarms.listActivePath });
  };

  return (
    <BaseTile
      rowKey={(record: Alarm, index) => `${record.occurDate}-${index}`}
      rowPropsGetter={() => ({
        to: routePaths.pages.alarms.listActivePath,
      })}
      height={height}
      title={t('t152')}
      isWidget={isWidget}
      icon={icons.ALARM_URGENT}
      columns={[
        {
          title: t('t4'),
          dataIndex: 'deviceName',
          width: '36%',
          ellipsis: true,
          render: (value, record: Alarm) => {
            return (
              <DeviceInfoColumn
                size="small"
                name={value}
                host={record.unitNumber}
                addr={record.addr}
              />
            );
          },
        },
        {
          title: t('t7'),
          dataIndex: 'name',
          width: '30%',
          ellipsis: true,
          render: value => <ValueColumn value={value} width="auto" />,
        },
        {
          title: t('t8'),
          width: '30%',
          ellipsis: true,
          render: (_, record: Alarm) => (
            <ValueColumn
              value={`${record.occurDate} ${record.occurTime}`}
              width="auto"
            />
          ),
        },
      ]}
      data={data}
      error={error}
      emptyStateText={t('t33')}
      isValidating={isValidating}
      isMaximized={isMaximized}
      rowCount={rowCount}
      onMaximize={onMaximize}
      onMinimize={onMinimize}
      onRefreshClick={() => handleMutate()}
      onTableRowClick={handleOnAlarmClick}
    />
  );
},
getIsEqual);

export { AlarmsTile };
