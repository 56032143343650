import * as React from 'react';

type CallbackFn = (...args: any[]) => void;

export function useThrottle<T extends CallbackFn>(fn: T, delay: number) {
  const lastCall = React.useRef<number>(0);

  const throttledFn = React.useCallback(
    (...args: Parameters<T>) => {
      const now = Date.now();

      if (now - lastCall.current >= delay) {
        lastCall.current = now;
        fn(...args);
      }
    },
    [fn, delay],
  );

  return throttledFn;
}
