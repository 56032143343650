import { saveAs } from 'file-saver';
import { HistoryCfg, Unit, UnitNetwork } from '@danfoss/etui-sm-xml';
import { DateFormat, Measurement, TimeFormat } from '@danfoss/etui-sm';
import { TFunction } from 'i18next';
import {
  ChartData,
  ChartPoint,
  DateRange,
  SampleRateConstants,
} from '../HistoryPage.types';

const getTimeString = (
  value: number,
  formats: Measurement,
  t?: TFunction,
): string => {
  const date = new Date(value);
  const is2DigitYear =
    formats.dateFormat === DateFormat.DDMMYY ||
    formats.dateFormat === DateFormat.MMDDYY;
  const localeString =
    formats.dateFormat === DateFormat.DDMMYYYY ||
    formats.dateFormat === DateFormat.DDMMYY
      ? 'en-GB'
      : 'en-US';

  const formatter = new Intl.DateTimeFormat(localeString, {
    day: '2-digit',
    month: '2-digit',
    year: is2DigitYear ? '2-digit' : 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: formats.timeFormat === TimeFormat.meridiem,
  });
  if (!(date instanceof Date) || isNaN(date.getTime())) {
    return t('t3805');
  }

  return formatter.format(date).replace(',', '');
};

const getTimeRows = (
  rows: ChartData[],
  formats: Measurement,
  t?: TFunction,
) => {
  const dataArr = rows.map(({ data }: { data: ChartPoint[] }) => data);
  const maxLength = Math.max(...dataArr.map(subarr => subarr.length));

  const timeRows = [];
  for (let i = 0; i < maxLength; i++) {
    let time = '';
    const row = dataArr.map(subarr => {
      time = `${getTimeString(subarr[i]?.time, formats, t)}`;
      return subarr[i]?.dataValue;
    });
    timeRows.push([time, ...row]);
  }
  return timeRows;
};

const getSampleRate = (sampleRate: string): string => {
  let csvSampleRate: string = '';
  const sampleRateInt = Number(sampleRate);

  if (sampleRateInt >= SampleRateConstants.ONEDAY) {
    csvSampleRate = `${(
      sampleRateInt / SampleRateConstants.ONEDAY
    ).toString()}Day`;
  } else if (
    sampleRateInt < SampleRateConstants.ONEDAY &&
    sampleRateInt >= SampleRateConstants.ONEHOUR
  ) {
    csvSampleRate = `${(
      sampleRateInt / SampleRateConstants.ONEHOUR
    ).toString()}Hour`;
  } else if (
    sampleRateInt < SampleRateConstants.ONEHOUR &&
    sampleRateInt >= 60
  ) {
    csvSampleRate = `${(
      sampleRateInt / SampleRateConstants.ONEMINUTE
    ).toString()}Min`;
  } else {
    csvSampleRate = `${sampleRate}Secs`;
  }

  return csvSampleRate;
};

function historyExportCSV(
  chartData: ChartData[],
  dateRange: DateRange,
  units: Unit[],
  formats: Measurement,
  t?: TFunction,
) {
  const csvData = chartData || [];
  const unitName = (units || []).map(
    ({ unit }: { unit: UnitNetwork | UnitNetwork[] }) => {
      // TODO: need check if there multi units
      return Array.isArray(unit)
        ? unit.map((item: UnitNetwork) => item.name).join(' ')
        : unit.name;
    },
  )?.[0];

  const dateRangeRow = `${getTimeString(
    new Date(dateRange.startDate).getTime(),
    formats,
    t,
  )} - ${getTimeString(new Date(dateRange.endDate).getTime(), formats, t)}`;
  const nameRow = csvData.map(({ param }: { param: HistoryCfg }) => param.name);
  const unitsName = Array(csvData.length)
    .fill('unit')
    .map(() => unitName);
  const digitalRow = csvData.map(
    ({ param }: { param: HistoryCfg }) => param.digital,
  );
  const sampleRateRow = csvData.map(({ param }: { param: HistoryCfg }) =>
    getSampleRate(param.actual_sample_rate),
  );

  const rows = [
    ['Date Range', dateRangeRow],
    ['Name', ...nameRow],
    ['Units', ...unitsName],
    ['Digital', ...digitalRow],
    ['Sample Rate', ...sampleRateRow],
  ];

  const timeRows = getTimeRows(chartData, formats, t);

  const csvFile = [...rows, ...timeRows].map(row => row.join()).join('\n');
  const csvFileName = `history-chart-data.csv`;

  // Saving URLs
  saveAs(`data:text/csv;charset=utf-8,${encodeURI(csvFile)}`, csvFileName);
}

export { historyExportCSV, getTimeString };
