import { ConfigurationTabItem } from '@danfoss/etui-sm-xml/';
import * as React from 'react';
import { areObjectsEqual } from 'utils';
import {
  ConfigurationModalContext,
  ModalSettings,
} from './ConfigurationModalContext';

function ConfigurationModalProvider({ children }) {
  const [openModals, setOpenModals] = React.useState([]);
  const [modalScrollSettings, setModalScrollSettings] = React.useState({});
  const [activeTab, setActiveTab] = React.useState<{
    [key: string]: ConfigurationTabItem;
  }>({});

  const toggleModals = (modalName: string) => {
    setOpenModals(prevModals =>
      prevModals.includes(modalName)
        ? prevModals.filter(modal => modal !== modalName)
        : [...prevModals, modalName],
    );
  };

  const clearAndSetModals = (modalName: string) => {
    setOpenModals([modalName]);
  };

  const handleSetActiveTab = (key: string, tab: ConfigurationTabItem) => {
    if (!areObjectsEqual(activeTab[key], tab))
      setActiveTab(prevState => ({
        ...prevState,
        [key]: tab,
      }));
  };

  const handleModalScrollSettings = ({
    modalName,
    scrollTop,
  }: ModalSettings) => {
    setModalScrollSettings(prevSettings => ({
      ...prevSettings,
      [modalName]: scrollTop,
    }));
  };

  const removeModalScrollSettings = (modalName: string) => {
    const newModalScrollSettings = { ...modalScrollSettings };
    delete newModalScrollSettings[modalName];
    setModalScrollSettings(newModalScrollSettings);
  };

  return (
    <ConfigurationModalContext.Provider
      value={{
        openModals,
        toggleModals,
        clearAndSetModals,
        activeTab,
        onSetActiveTab: handleSetActiveTab,
        modalScrollSettings,
        handleModalScrollSettings,
        removeModalScrollSettings,
      }}
    >
      {children}
    </ConfigurationModalContext.Provider>
  );
}

export { ConfigurationModalProvider };
