import '@danfoss/etui-sm-xml';
import '@danfoss/etui-core';
import './constants/software-versions.js';
import { f as fetchLatestVersionFilename } from './fetch-latest-version-filename-d4771f3b.js';
import './utils/get-is-misc-device.js';
import { getVersionFromFilename } from './utils/get-version-from-filename.js';

const fetchLatestVersion = async (latestFileUrl, updateType) => {
  const filename = await fetchLatestVersionFilename(latestFileUrl);
  return getVersionFromFilename(filename, updateType);
};
const extractVersion = (key, currentVersionsFetched) => currentVersionsFetched === null || currentVersionsFetched === void 0 ? void 0 : currentVersionsFetched.find(v => (v === null || v === void 0 ? void 0 : v.software) === key);

export { extractVersion as e, fetchLatestVersion as f };
