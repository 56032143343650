import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs, useResponsive } from '@danfoss/etui-core';
import { useTheme } from '@danfoss/etui-system';
import { Div, P } from '@danfoss/etui-system-elements';
import {
  LoadStates,
  useApp,
  useAuth,
  useUnit,
  useXmlResource,
} from '@danfoss/etui-sm';
import { getSoftwareVersion } from 'utils/get-software-version';
import { isIE } from 'react-device-detect';
import { AddressBookItem } from 'types';
import {
  addAddressBookToCookie,
  mergeCookieAddressBook,
} from 'utils/addressbook-helpers';
import { LoginLayout } from 'components/LoginLayout';
import * as S from './styles';
import { useLogin, useLoginParams } from './hooks';
import { BrowserSupportInfo } from './components/BrowserSupportInfo';
import { LoginForm, MoreInfo, RecentSites } from './components';

enum LOGIN_TABS {
  LOGIN_FORM = 'login-form',
  ADDRESS_BOOK = 'address-book',
  MORE = 'more',
}

const formTabs = [
  {
    type: LOGIN_TABS.LOGIN_FORM,
    title: 't924',
  },
  {
    type: LOGIN_TABS.ADDRESS_BOOK,
    title: 't3457',
  },
  {
    type: LOGIN_TABS.MORE,
    title: 't3219',
  },
];

export function LoginPage() {
  const { t } = useTranslation();
  const theme = useTheme();
  const [state] = useApp();
  const { loginError } = useAuth();
  const { loadState } = useUnit();
  const { ip: xmlBackendIp, setIp, url } = useXmlResource();
  const { paramIp, paramUser, paramPassword, paramProxyConfig } =
    useLoginParams();

  const [isAuthenticating, setIsAuthenticating] = React.useState(false);

  const login = useLogin();

  const { screenIsAtMost } = useResponsive({
    md: parseInt(theme.breakpoints[1], 10),
  });
  const isMdView = screenIsAtMost('md');

  const [hasIpError, setHasIpError] = React.useState(false);
  const [prefilledData, setPrefilledData] =
    React.useState<AddressBookItem | null>(null);
  const [selectedTab, setSelectedTab] = React.useState(LOGIN_TABS.LOGIN_FORM);

  const switchToLoginForm = (
    data: AddressBookItem,
    isFromRecents: boolean = false,
  ) => {
    setSelectedTab(LOGIN_TABS.LOGIN_FORM);
    if (isFromRecents) {
      delete data.password;
    }
    setPrefilledData(data);
  };

  const softwareVersion = getSoftwareVersion();
  const hasLoginError = !!loginError;

  const onTabSelect = (tab: LOGIN_TABS) => {
    if (isAuthenticating) return;
    setIp('');
    setPrefilledData(null);
    setSelectedTab(tab);
  };

  React.useEffect(() => {
    if (paramIp && xmlBackendIp === paramIp) {
      const credentialsProvided = paramUser && paramPassword;
      const shouldAutoLogin =
        paramIp && (credentialsProvided || paramProxyConfig.token);
      if (shouldAutoLogin) {
        setIsAuthenticating(true);
        login({
          user: paramUser,
          password: paramPassword,
          ip: {
            label: paramIp,
            value: paramIp.replace(`${state.protocol}//`, ''),
          },
        });
      }

      // prefill form when logining to https site from http (and vice versa) from 'recents' tab on login page
      if (paramUser && !paramPassword) {
        const creds = {
          ipAddress: paramIp,
          user: paramUser,
        };
        switchToLoginForm(creds as AddressBookItem);
      }
    }
  }, [url, paramIp, paramPassword, paramProxyConfig, xmlBackendIp]);

  React.useEffect(() => {
    mergeCookieAddressBook();
    return () => setTimeout(addAddressBookToCookie, 0);
  }, []);

  const tabContents = {
    [LOGIN_TABS.LOGIN_FORM]: (
      <LoginForm
        onSetIpError={setHasIpError}
        prefilledData={prefilledData}
        login={login}
        setIsAuthenticating={setIsAuthenticating}
        isAuthenticating={isAuthenticating}
      />
    ),
    [LOGIN_TABS.ADDRESS_BOOK]: (
      <RecentSites switchToLoginForm={switchToLoginForm} />
    ),
    [LOGIN_TABS.MORE]: <MoreInfo />,
  };

  const tabContent = tabContents[selectedTab];

  return (
    <LoginLayout>
      {isIE ? (
        <BrowserSupportInfo />
      ) : (
        <>
          {paramProxyConfig?.proxyAddress ? (
            <S.ProxyTextContainer>
              {loadState !== LoadStates.DONE && !hasLoginError ? (
                <>
                  <Div minWidth="2.0em" />
                  <Div>{t('t3127')}</Div>
                  <S.LoadingEllipsisDiv />
                </>
              ) : hasLoginError || hasIpError ? (
                <Div textAlign="center">{t('t3128')}</Div>
              ) : (
                <Div>
                  {!paramProxyConfig.ipAddress && (
                    <P color={theme.palette.common.white}>{t('t3666')}</P>
                  )}
                  {!paramProxyConfig.protocol && (
                    <P
                      pt={`${theme.spacing.sm}px`}
                      color={theme.palette.common.white}
                    >
                      {t('t3667')}
                    </P>
                  )}
                </Div>
              )}
            </S.ProxyTextContainer>
          ) : (
            <S.FormContainer isMdView={isMdView}>
              <Tabs
                key="loginTabs"
                onChange={onTabSelect}
                value={selectedTab}
                styles={{
                  root: {
                    mb: `${theme.spacing.xlg}px`,
                    pl: `${theme.spacing.xlg}px`,
                  },
                }}
              >
                {formTabs.map(({ type, title }) => (
                  <Tabs.Tab
                    key={type}
                    value={type}
                    tab={t(title)}
                    styles={{ root: { borderBottom: 0 } }}
                    disabled={isAuthenticating}
                  />
                ))}
              </Tabs>
              {tabContent}
            </S.FormContainer>
          )}
          <S.SoftwareVersion>{softwareVersion}</S.SoftwareVersion>
        </>
      )}
    </LoginLayout>
  );
}
