import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col, ContainerDimensions } from '@danfoss/etui-core';
import DashboardContextProvider from '@danfoss/etui-core/Dashboard/DashboardContext';
import { XML_DEVICE_LIST } from '@danfoss/etui-sm-xml';
import {
  useAuth,
  useXmlResource,
  useUnit,
  useApp,
} from '@danfoss/etui-sm/context';
import { UnitsView } from '@danfoss/etui-sm/components';
import { routePaths } from 'routePaths';
import { useQuery } from 'hooks';
import useSWR from 'swr';
import { useFeatureFlag } from 'hooks/featurehub/FeatureHubContext';
import { DeviceTile } from './DeviceTile';
import { AlarmsTile } from './AlarmsTile';
import { getUnits } from './actions';
import {
  getDeviceTypesByUnits,
  getMaximizedQueryByDeviceType,
  MaximizedQueryValue,
} from './utils';
import { HomePageDashboard } from './components';

function HomePage() {
  const query = useQuery();
  const { url: xmlBackendURL } = useXmlResource();
  const { user } = useAuth();

  const FlexibleDashboardEnabled = useFeatureFlag('FlexibleDashboard');
  const equipmentEnabled = useFeatureFlag('Equipment');
  const {
    units: initialUnits,
    loadState: initialUnitsLoadState,
    loadError: initialUnitsLoadError,
  } = useUnit();
  const { push, replace } = useHistory();
  const [state] = useApp();

  const { data: newUnits, error: unitError } = useSWR(
    ['home', xmlBackendURL, user, state.protocol],
    getUnits,
    {
      revalidateOnMount: false,
      refreshInterval: 15000,
    },
  );

  const units = newUnits || initialUnits;
  const isLoadingInitialUnits = initialUnitsLoadState !== 'done';
  const isLoadingNewUnits = !units && !unitError;
  const maximizedQueryName = 'maximized';

  const setMaximizedQueryValue = (value: MaximizedQueryValue) => {
    query.set(maximizedQueryName, value);
    push({ pathname: routePaths.pages.home, search: query.toString() });
  };

  const getIsMaximizedQueryValueSet = (value: MaximizedQueryValue) => {
    const maximizedQueryValue = query.get(maximizedQueryName);
    if (maximizedQueryValue) {
      return maximizedQueryValue === value;
    }
    return false;
  };

  const onMaximizeDeviceTile = React.useCallback((type: XML_DEVICE_LIST) => {
    const maximizedQueryValueToSet = getMaximizedQueryByDeviceType(type);
    setMaximizedQueryValue(maximizedQueryValueToSet);
  }, []);

  const onMinimizeTile = React.useCallback(
    () => replace(routePaths.pages.home),
    [replace],
  );

  const deviceTypes = equipmentEnabled ? getDeviceTypesByUnits(units) : [];

  const renderAlarmsTile = (height: number) => {
    let colMd = 12;
    let colSm = 12;
    switch (deviceTypes.length) {
      case 0:
      case 2:
        colSm = 12;
        colMd = 12;
        break;
      case 1:
      case 3:
      case 4:
        colSm = 6;
        colMd = 6;
        break;
      case 5:
        colSm = 6;
        colMd = 4;
        break;
      default:
        colMd = 12;
        break;
    }

    return (
      <Col
        xs={12}
        sm={colSm}
        md={colSm}
        lg={colMd}
        // @ts-ignore
        testId="device-widget-alarms"
      >
        <AlarmsTile
          height={height}
          rowCount={deviceTypes.length === 1 ? 1 : 2}
          isMaximized={getIsMaximizedQueryValueSet('alarms')}
          onMaximize={() => setMaximizedQueryValue('alarms')}
          onMinimize={onMinimizeTile}
        />
      </Col>
    );
  };

  const renderDeviceTile = (
    height: number,
    deviceType: XML_DEVICE_LIST,
    index: number,
  ) => {
    let colLg = 12;
    let colSm = 12;
    switch (deviceTypes.length) {
      case 1:
      case 2:
      case 3:
        colLg = 6;
        colSm = 6;
        break;
      case 4:
        colLg = index === 0 ? 6 : 4;
        colSm = 6;
        break;
      case 5:
        colLg = 4;
        colSm = 6;
        break;
      default:
        colLg = 12;
        break;
    }

    return (
      <Col
        xs={12}
        sm={colSm}
        md={colSm}
        lg={colLg}
        key={deviceType}
        // @ts-ignore
        testId={`device-widget-${deviceType}`}
      >
        <DeviceTile
          height={height}
          type={deviceType}
          rowCount={deviceTypes.length === 1 ? 1 : 2}
          isMaximized={getIsMaximizedQueryValueSet(
            getMaximizedQueryByDeviceType(deviceType),
          )}
          onMaximize={onMaximizeDeviceTile}
          onMinimize={onMinimizeTile}
        />
      </Col>
    );
  };

  return (
    <UnitsView
      isFullPage={false}
      isLoading={isLoadingInitialUnits || isLoadingNewUnits}
      isFailed={!!unitError || !!initialUnitsLoadError}
      units={units || initialUnits}
      onRetry={window.location.reload}
    >
      <>
        {FlexibleDashboardEnabled ? (
          <DashboardContextProvider>
            <HomePageDashboard deviceTypes={deviceTypes} />
          </DashboardContextProvider>
        ) : (
          <ContainerDimensions>
            {({ height }) => (
              <Container fluid={true}>
                <Row>
                  {renderAlarmsTile(height)}
                  {deviceTypes.map((deviceType, index) =>
                    renderDeviceTile(height, deviceType, index),
                  )}
                </Row>
              </Container>
            )}
          </ContainerDimensions>
        )}
      </>
    </UnitsView>
  );
}

export default HomePage;
