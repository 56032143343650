import React from 'react';
import { Div } from '@danfoss/etui-system-elements';
import { useTranslation } from 'react-i18next';
import { Button } from '@danfoss/etui-core';
import { ContentSpinner, useModal } from '@danfoss/etui-sm';
import { LayoutFlowTitle } from '../../../index';
import { RefrigCopySaveFileModal } from '../RefrigCopySaveFileModal';
import { useRefrigCopyContent } from '../../../../hooks';
import { REFRIG_COPY_STEP } from '../../types/RefrigCopy.types';
import { useRefrigCopy } from '../../context';

interface RefrigCopyFilesFlowContentProps {
  onExit?: () => void;
  tableAddress: string;
}

export const FilesFlowContent = ({
  onExit,
  tableAddress,
}: RefrigCopyFilesFlowContentProps) => {
  const { t } = useTranslation();
  const { isValidating, tableContentData } = useRefrigCopyContent(
    REFRIG_COPY_STEP.FILES,
    '',
    tableAddress,
  );
  const { currentUnitUrl: url } = useRefrigCopy();

  const bnum = tableContentData && parseInt(tableContentData[1].bnum, 10);

  const [showSaveFileModal] = useModal(RefrigCopySaveFileModal, {
    onExit,
    bnum,
    url,
  });

  return (
    <Div mt="10px" height="100%" testId="file-flow-content">
      <LayoutFlowTitle
        title={t('t653')}
        subTitle={t('t3448')}
        helpText={t('t3449')}
      />
      {isValidating ? (
        <Div height="100%">
          <ContentSpinner size={1} transparent={true} />
        </Div>
      ) : (
        <Button
          width="150px"
          variant="secondary"
          styles={{ root: { mr: 10 } }}
          testId="unit-config-saveFile-button"
          onClick={showSaveFileModal}
        >
          {t('t266')}
        </Button>
      )}
    </Div>
  );
};
