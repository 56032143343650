import {
  Checkbox,
  Col,
  Container,
  Icon,
  Row,
  Tile,
  icons,
} from '@danfoss/etui-core';
import { Div, Span } from '@danfoss/etui-system-elements';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { useRefrigLayout } from '../../context';
import { DEVICE_TYPE } from '../../types/DiscoveryPage.types';
import { CasesSearchInput } from '../CasesSearchInput';
import { LayoutFlowTitle } from '../LayoutContent';
import { SuctionGroupCasesTable } from './SuctionGroupCasesTable';
import { SuctionGroupCompressorTable } from './SuctionGroupCompressorTable';

const titleStyle: React.CSSProperties = {
  color: 'black',
};

interface SuctionGroupFlowContentProps {
  tableDataLoading?: boolean;
}

export const SuctionGroupsFlowContent = ({
  tableDataLoading,
}: SuctionGroupFlowContentProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    discoveryTableData,
    setCircuitsTableData,
    setCurrentSelectedCase,
    allowMultiCaseCreation,
    setAllowMultiCaseCreation,
    setCurrentSearchInputText,
    setCurrentSelectedCaseDeviceIds,
    setSelectedCompressorTreeDeviceId,
    setCurrentSectionsOpen,
  } = useRefrigLayout();
  const filterCircuitsData = () => {
    const filteredCircuitsData = discoveryTableData?.filter(
      device =>
        device.deviceType === DEVICE_TYPE.CIRCUIT ||
        device.deviceType === DEVICE_TYPE.GEN_CIRCUIT,
    );

    setCircuitsTableData(filteredCircuitsData);
  };

  const handleSearchInputText = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setCurrentSearchInputText(value);
  };

  const handleAllowMultiCaseCreation = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { checked } = event.target;
    setAllowMultiCaseCreation(checked);
  };

  React.useEffect(() => {
    return () => {
      setCurrentSelectedCase(null);
      setCurrentSelectedCaseDeviceIds([]);
      setSelectedCompressorTreeDeviceId(undefined);
      setCurrentSectionsOpen([]);
    };
  }, []);

  return (
    <Div mt="20px">
      <Container fluid={true}>
        <Row>
          <Col xs={6}>
            <LayoutFlowTitle
              title={t('t519')}
              subTitle={t('t3440')}
              helpText={t('t3441')}
            />
            <Div>
              <Span mr="20px">
                <Checkbox
                  testId="allow-multi-case-checkbox"
                  name="controlled"
                  id="controlled"
                  checked={allowMultiCaseCreation}
                  onChange={handleAllowMultiCaseCreation}
                />
                <Span ml="5px">{` ${t('t522')}`}</Span>
              </Span>
            </Div>
            <Div
              testId="warning-text"
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                marginTop: '20px',
                marginLeft: '20px',
              }}
            >
              <Icon
                glyph={icons.WARNING}
                styles={{
                  root: {
                    color: theme.palette.warning.main,
                  },
                }}
                size={32}
              />
              <Div style={{ marginTop: '5px', marginLeft: '5px' }}>
                {t('t3678')}
              </Div>
            </Div>
          </Col>
          <Col xs={4}>
            <CasesSearchInput
              onChange={handleSearchInputText}
              name="searchCaseInput"
              testId="searchCaseInput"
              label="Search cases"
            />
          </Col>
        </Row>
      </Container>
      <Container fluid={true}>
        <Row>
          <Col xs={6}>
            <Div mt="20px" testId="SuctionGroupCompressorTableDiv">
              <Span style={titleStyle}>{t('t518').toLocaleUpperCase()}</Span>
              <Tile testIds={{ content: 'refrig-suctionGroupCompressor-tile' }}>
                <SuctionGroupCompressorTable
                  tableDataLoading={tableDataLoading}
                />
              </Tile>
            </Div>
          </Col>
          <Col xs={6}>
            <Div mt="20px" testId="SuctionGroupCasesTableDiv">
              <Span style={titleStyle}>{t('t521').toLocaleUpperCase()}</Span>
              <Tile testIds={{ content: 'refrig-suctionGroupCases-tile' }}>
                <SuctionGroupCasesTable
                  filterCircuits={filterCircuitsData}
                  tableDataLoading={tableDataLoading}
                />
              </Tile>
            </Div>
          </Col>
        </Row>
      </Container>
    </Div>
  );
};
