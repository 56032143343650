import { DateFormat } from './get-measurements-by-unit.js';

const getFormattedDate = (dateFormat, milliseconds) => {
  const date = milliseconds ? new Date(milliseconds) : new Date();
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  const shortYear = year.toString().slice(-2);
  switch (dateFormat) {
    case DateFormat.MMDDYY:
      return `${month}/${day}/${shortYear}`;
    case DateFormat.MMDDYYYY:
      return `${month}/${day}/${year}`;
    case DateFormat.DDMMYYYY:
      return `${day}/${month}/${year}`;
    case DateFormat.DDMMYY:
      return `${day}/${month}/${shortYear}`;
    default:
      throw new Error('Invalid date format');
  }
};

export { getFormattedDate };
