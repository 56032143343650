import {
  ContainerDimensions,
  DashboardBase,
  Widget,
  WidgetLayout,
} from '@danfoss/etui-core';
import { Div } from '@danfoss/etui-system-elements';
import { useLocalStorage } from 'hooks';
import React from 'react';
import { useFeatureFlag } from 'hooks/featurehub/FeatureHubContext';
import { DashboardAlarmsWidget } from './DashboardAlarmsWidget';
import { DashboardDeviceWidget } from './DashboardDeviceWidget';

type WidgetLayoutWithId = {
  id: string;
  layout: WidgetLayout;
};

export function HomePageDashboard({ deviceTypes }) {
  const [widgets, setWidgets] = React.useState(null);

  const alarmsEnabled = useFeatureFlag('AlarmManagement');
  const w = deviceTypes.length === 1 ? 12 : 4;
  const [storedValue, saveWidgetLayouts] = useLocalStorage<
    WidgetLayoutWithId[]
  >('homeDashboardLayout');

  const onLayoutChange = (changedWidgets: Widget<any>[]) => {
    const layouts = changedWidgets.map(({ layout, id }) => ({
      id,
      layout,
    }));
    saveWidgetLayouts(layouts);
  };
  React.useEffect(() => {
    const alarmsLayout = storedValue?.find(l => l.id === 'alarms')?.layout;
    const alarmsWidget = {
      Component: DashboardAlarmsWidget,
      id: `alarms`,
      testIds: {
        content: 'device-widget-alarms',
      },
      layout: alarmsLayout || {
        x: 0,
        y: 0,
        w,
        h: 3,
        isResizable: true,
      },
    };
    const deviceWidgets = deviceTypes.map((deviceType, index) => {
      const x = (index % 3) * w;
      const deviceLayout = storedValue?.find(
        l => `${l.id}` === `${deviceType}`,
      )?.layout;
      return {
        Component: DashboardDeviceWidget,
        id: `${deviceType}`,
        layout: deviceLayout || {
          x,
          y: 0,
          w,
          h: 3,
          isResizable: true,
        },
        testIds: {
          content: `device-widget-${deviceType}`,
        },
        props: {
          type: deviceType,
        },
      };
    });
    setWidgets(
      alarmsEnabled ? [alarmsWidget, ...deviceWidgets] : deviceWidgets,
    );
  }, [JSON.stringify(deviceTypes), alarmsEnabled]);
  return (
    <ContainerDimensions>
      {({ height }) => {
        return (
          <Div height={height - 50}>
            <DashboardBase
              testId="homePage-dashboard"
              onWidgetsChanged={onLayoutChange}
              widgets={widgets}
            />
          </Div>
        );
      }}
    </ContainerDimensions>
  );
}
