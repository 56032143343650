import * as React from 'react';
import { Button } from '@danfoss/etui-core';
import { useTranslation } from 'react-i18next';
import { XML_REFRIG_COPY_TAB_INDEX } from '@danfoss/etui-sm-xml';
import { Div } from '@danfoss/etui-system-elements';
import { useAuth } from '@danfoss/etui-sm';
import { useRefrigCopy } from './context';
import { sendBdCommands } from './actions';

export interface RefrigCopyModalActionsProps {
  onCancel: () => void;
  handleOnFinish: () => void;
}
export function RefrigCopyModalActions({
  onCancel,
  handleOnFinish,
}: RefrigCopyModalActionsProps) {
  const { t } = useTranslation();
  const {
    step,
    setStep,
    activeStepNumber,
    setActiveStepNumber,
    refrigSteps,
    setSearchMode,
    setIsAllPageDataFetched,
    setAllPageData,
    setPage,
    setPageValues,
    setSourceGraphTablePageContent,
    setSaveCompleted,
    setAllPageSourceAlarmData,
    setMultipage,
    setSourceAlarmTablePageContent,
    setEnableAllAlarms,
    setDevicesTableContent,
    setSelectAll,
    setCopyDevicesTableContent,
    setIsCopyDevicesDataFetched,
    setCopyDevicesTableData,
    setCopyDevicesListItems,
    isCopyFinished,
    setIsCopyFinished,
    isCopyControllerLoading,
    isCopyControllerSelected,
    isLoadingConfigFile,
    setParamTableData,
    setAlarmsTableData,
    setGraphTableData,
    setIsEmptyCopyDevicesTableData,
    setIsBackToSourceController,
    isAK2,
  } = useRefrigCopy();

  const setDefault = () => {
    setSearchMode(false);
    setIsAllPageDataFetched(false);
    setAllPageData([]);
    setPage('0');
    setPageValues([]);
    setSourceGraphTablePageContent([]);
    setSaveCompleted(false);
    setAllPageSourceAlarmData([]);
    setMultipage('');
    setSourceAlarmTablePageContent([]);
    setEnableAllAlarms(false);
    setDevicesTableContent([]);
    setSelectAll([]);
    setCopyDevicesTableContent([]);
    setIsCopyDevicesDataFetched(false);
    setCopyDevicesTableData(null);
    setCopyDevicesListItems([]);
    setIsCopyFinished(false);
    setParamTableData([]);
    setAlarmsTableData({ alarmTableContent: [] });
    setGraphTableData({ graphTableContent: [] });
    setIsEmptyCopyDevicesTableData(false);
  };
  const { currentUnitUrl: url } = useRefrigCopy();
  const { user } = useAuth();
  const handleOnBack = () => {
    let currentStep = step;
    let currentStepNumber = activeStepNumber;
    currentStep -=
      isAK2 &&
      (step === +XML_REFRIG_COPY_TAB_INDEX.SOURCE_ALARMS ||
        step === +XML_REFRIG_COPY_TAB_INDEX.ALARMS)
        ? 2
        : 1;
    currentStepNumber -=
      isAK2 &&
      (step === +XML_REFRIG_COPY_TAB_INDEX.SOURCE_ALARMS ||
        step === +XML_REFRIG_COPY_TAB_INDEX.ALARMS)
        ? 2
        : 1;

    if (
      step === +XML_REFRIG_COPY_TAB_INDEX.SOURCE_GRAPH ||
      step === +XML_REFRIG_COPY_TAB_INDEX.SOURCE_ALARMS ||
      step === +XML_REFRIG_COPY_TAB_INDEX.DEVICES ||
      step === +XML_REFRIG_COPY_TAB_INDEX.COPY ||
      step === +XML_REFRIG_COPY_TAB_INDEX.PARAMETERS ||
      step === +XML_REFRIG_COPY_TAB_INDEX.ALARMS ||
      step === +XML_REFRIG_COPY_TAB_INDEX.GRAPH
    ) {
      setDefault();
    }
    if (
      step === +XML_REFRIG_COPY_TAB_INDEX.SOURCE_SETTINGS ||
      (isAK2 && step === +XML_REFRIG_COPY_TAB_INDEX.SOURCE_ALARMS)
    ) {
      setIsBackToSourceController(true);
    }
    if (currentStep < +XML_REFRIG_COPY_TAB_INDEX.SOURCE_CONTROLLER) {
      onCancel();
    }
    if (
      currentStep < +XML_REFRIG_COPY_TAB_INDEX.COPY &&
      currentStep >= +XML_REFRIG_COPY_TAB_INDEX.SOURCE_CONTROLLER
    ) {
      setStep(currentStep);
    }
    if (currentStepNumber >= refrigSteps[0]?.stepNumber) {
      setActiveStepNumber(currentStepNumber);
    }
  };

  const handleOnNextButton = () => {
    let currentStep = step;
    let currentStepNumber = activeStepNumber;
    currentStep +=
      isAK2 &&
      (step === +XML_REFRIG_COPY_TAB_INDEX.SOURCE_CONTROLLER ||
        step === +XML_REFRIG_COPY_TAB_INDEX.FILES)
        ? 2
        : 1;
    currentStepNumber +=
      isAK2 &&
      (step === +XML_REFRIG_COPY_TAB_INDEX.SOURCE_CONTROLLER ||
        step === +XML_REFRIG_COPY_TAB_INDEX.FILES)
        ? 2
        : 1;
    if (step === +XML_REFRIG_COPY_TAB_INDEX.COPY) {
      sendBdCommands(url, user);
      handleOnFinish();
    }
    if (
      step === +XML_REFRIG_COPY_TAB_INDEX.SOURCE_GRAPH ||
      step === +XML_REFRIG_COPY_TAB_INDEX.SOURCE_ALARMS ||
      step === +XML_REFRIG_COPY_TAB_INDEX.DEVICES ||
      step === +XML_REFRIG_COPY_TAB_INDEX.PARAMETERS ||
      step === +XML_REFRIG_COPY_TAB_INDEX.ALARMS ||
      step === +XML_REFRIG_COPY_TAB_INDEX.GRAPH
    ) {
      setDefault();
    }
    if (step === +XML_REFRIG_COPY_TAB_INDEX.SOURCE_CONTROLLER) {
      setIsBackToSourceController(false);
    }
    if (
      currentStep < +XML_REFRIG_COPY_TAB_INDEX.COPY &&
      currentStep >= +XML_REFRIG_COPY_TAB_INDEX.SOURCE_CONTROLLER
    ) {
      setStep(currentStep);
    }
    if (currentStepNumber <= refrigSteps[refrigSteps?.length - 1]?.stepNumber) {
      setActiveStepNumber(currentStepNumber);
    }
  };
  const mr = step === +XML_REFRIG_COPY_TAB_INDEX.DEVICES ? 160 : 10;
  return (
    <Div float="right">
      <Button
        width="150px"
        testId="refrig-copy-back-button"
        onClick={handleOnBack}
        variant="secondary"
        styles={{ root: { mr } }}
        disabled={step === +XML_REFRIG_COPY_TAB_INDEX.COPY && !isCopyFinished}
      >
        {step === +XML_REFRIG_COPY_TAB_INDEX.SOURCE_CONTROLLER
          ? t('t45')
          : t('t710')}
      </Button>
      {step !== +XML_REFRIG_COPY_TAB_INDEX.DEVICES ? (
        <Button
          width="150px"
          variant="primary"
          styles={{ root: { mr: 10 } }}
          testId="refrig-copy-open-button"
          onClick={handleOnNextButton}
          disabled={
            (step === +XML_REFRIG_COPY_TAB_INDEX.COPY && !isCopyFinished) ||
            (step === +XML_REFRIG_COPY_TAB_INDEX.SOURCE_CONTROLLER &&
              (isCopyControllerLoading ||
                !isCopyControllerSelected ||
                isLoadingConfigFile))
          }
        >
          {step === +XML_REFRIG_COPY_TAB_INDEX.COPY ? t('t430') : t('t118')}
        </Button>
      ) : null}
    </Div>
  );
}
