import {
  ConfigurationListItem,
  fetchXMLData,
  User,
  XML_ACTION,
} from '@danfoss/etui-sm-xml';
import { TABLE_ADDRESS } from 'pages/ConfigurationPage/Configuration';

export interface PollingConfigResponse {
  tableaddress: string[];
  items: { l: ConfigurationListItem[] };
}

export async function getPollingConfig(
  url: string,
  user: User,
  skipSessionUpdate: boolean = false,
): Promise<PollingConfigResponse> {
  const attributes = {
    user: user?.user,
    password: user?.password,
    lang: user?.language,
    tableaddress: TABLE_ADDRESS.NETWORK_NODES,
    version: 'C',
    index: '0',
    storeview_only: '1',
    action: XML_ACTION.READ_LIST,
    ...(skipSessionUpdate ? { session_update: 'no' } : {}),
  };

  try {
    return await fetchXMLData<PollingConfigResponse>({
      url,
      attributes,
    });
  } catch (e) {
    return null;
  }
}
