import { ConfigurationListItem, User } from '@danfoss/etui-sm-xml';
import {
  getPollingConfig,
  PollingConfigResponse,
} from '../actions/polling-actions';
import * as constants from './constants';

const isPollingConfigItem = ({
  token,
  width,
}: ConfigurationListItem): boolean =>
  token.split('|')[1] === constants.POLLING_CONFIG_TOKEN &&
  width.split('|').length > 1;

export async function isPollingConfigSuspended(
  url: string,
  user: User,
  skipSessionUpdate: boolean = false,
): Promise<boolean> {
  const data: PollingConfigResponse = await getPollingConfig(
    url,
    user,
    skipSessionUpdate,
  );

  if (!data || !data.items?.l) {
    return null;
  }

  const pollingStatus: string =
    data.items.l.find(isPollingConfigItem)?.width?.split('|')[1] || null;

  return pollingStatus === constants.POLLING_CONFIG_SUSPENDED_WIDTH;
}
