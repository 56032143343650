import React from 'react';
import { Button } from '@danfoss/etui-core';
import { Div, Input } from '@danfoss/etui-system-elements';
import { ConfigurationListItem } from '@danfoss/etui-sm-xml';
import { useTranslation } from 'react-i18next';

export interface ConfigurationListItemEditBrowseProps {
  handleBrowse: (file: File, item?: ConfigurationListItem) => void;
  isCopyWizard?: boolean;
  item?: ConfigurationListItem;
}

export const ConfigurationListItemEditBrowse = ({
  handleBrowse,
  isCopyWizard,
  item,
}: ConfigurationListItemEditBrowseProps) => {
  const { t } = useTranslation();
  const inputRef = React.useRef(null);
  const openBrowseWindow = () => inputRef.current.click();
  const getAcceptType = () => {
    if (isCopyWizard) {
      return '.cfz';
    }
    return '';
  };
  return (
    <>
      <Input
        type="file"
        hidden={true}
        accept={getAcceptType()}
        multiple={false}
        onChange={e => {
          const file = e ? e.target.files[0] : null;
          handleBrowse(file, item);
        }}
        elRef={inputRef}
      />
      <Div>
        <Button
          width="100%"
          small={true}
          variant="secondary"
          testId="config-browse-button"
          onClick={openBrowseWindow}
        >
          {t('t834')}
        </Button>
      </Div>
    </>
  );
};
