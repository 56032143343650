import { TimeFormat } from './get-measurements-by-unit.js';

const getFormattedTime = (timeFormat, milliseconds) => {
  const date = milliseconds ? new Date(milliseconds) : new Date();
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  if (timeFormat === TimeFormat.meridiem) {
    const hours12 = `${+hours % 12 || 12}`.padStart(2, '0');
    const ampm = hours >= '12' ? 'PM' : 'AM';
    return `${hours12}:${minutes}:${seconds} ${ampm}`;
  }
  return `${hours}:${minutes}:${seconds}`;
};

export { getFormattedTime };
