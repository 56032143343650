import { fetchXMLData, XML_ACTION } from '@danfoss/etui-sm-xml';

const fetchInitialUnit = async (url, _ref) => {
  let {
    user,
    password,
    language
  } = _ref;
  const initialUnit = await fetchXMLData({
    url,
    attributes: {
      user,
      password,
      lang: language,
      action: XML_ACTION.READ_UNITS,
      storeview_only: '1',
      version: 'C'
    }
  });
  return initialUnit;
};

export { fetchInitialUnit };
