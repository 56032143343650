import * as React from 'react';
import {
  Button,
  Modal,
  Row,
  Col,
  Checkbox,
  H5,
  ellipsis,
  useResponsive,
} from '@danfoss/etui-core';
import { Label, Div } from '@danfoss/etui-system-elements';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@danfoss/etui-system';
import { ConfigurationListItem } from '@danfoss/etui-sm-xml';
import { useConfigurationModals } from '../../context';
import { BaseEditProps } from './ConfigurationListItemEdit';
import { ConfigurationListitemEditInputAddon } from './ConfigurationListItemEditInputAddon';
import { ConfigurationListItemEditLoadingOverlay } from './ConfigurationListItemEditLoadingOverlay';
import { useFlash } from './hooks/useFlash';

function getIsChecked(compoundValue: number, compoundIndex: number) {
  return (compoundValue & (1 << compoundIndex)) > 0;
}

interface WithSubItems {
  subitem: string[];
}

function getCompoundItemIndex(
  itemIndexInCol: number,
  colIndex: number,
  listdata: WithSubItems[],
): number {
  return (
    itemIndexInCol +
    listdata
      .slice(0, colIndex)
      .reduce((acc, { subitem }) => acc + subitem.length, 0)
  );
}

function getStateValue(item: ConfigurationListItem) {
  return (item.listdata as { subitem: string[] }[]).map(
    ({ subitem }, colIndex, listdata) => {
      return subitem.map((authRule, authRuleIndexInCol) => {
        const compoundIndex = getCompoundItemIndex(
          authRuleIndexInCol,
          colIndex,
          listdata as unknown as WithSubItems[],
        );
        return {
          compoundIndex,
          checked: getIsChecked(parseInt(item.iVal, 10), compoundIndex),
          value: authRule,
        };
      });
    },
  );
}

function ConfigurationListItemEditAuth({
  item,
  isAuthorized,
  onSave,
}: BaseEditProps) {
  const { openModals, toggleModals } = useConfigurationModals();
  const [isOpen, setIsOpen] = React.useState(
    openModals.includes(item?.name + item?.li),
  );
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [flashError, isError] = useFlash(2000);
  const [flashSuccess, isSuccess] = useFlash(2000);
  const [viewListItems, setViewListItems] = React.useState<
    { compoundIndex: number; value: any; checked: boolean }[][]
  >(() => getStateValue(item));
  const { t } = useTranslation();
  const theme = useTheme();

  const { screenIsAtMost } = useResponsive({
    sm: parseInt(theme.breakpoints[1], 10),
  });

  const isSmView = screenIsAtMost('sm', ['portrait', 'landscape']);
  const iconXOffset = isSubmitting ? 42 : 12;

  const handleOpen = () => {
    setIsOpen(true);
    toggleModals(item.name + item.li);
  };

  const handleClose = () => {
    setIsOpen(false);
    toggleModals(item.name + item.li);
  };

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      await onSave(item, { ival: getNewValue(), fval: '' });
      flashSuccess();
      handleClose();
    } catch (e) {
      flashError();
      reset();
    } finally {
      setIsSubmitting(false);
    }
  };

  function reset() {
    setViewListItems(getStateValue(item));
  }

  function cancel() {
    handleClose();
    reset();
  }

  function handleCheckboxChange(
    checked: boolean,
    colIndex: number,
    itemIndex: number,
  ) {
    setViewListItems([
      ...viewListItems.slice(0, colIndex),
      [
        ...viewListItems[colIndex].slice(0, itemIndex),
        { ...viewListItems[colIndex][itemIndex], checked },
        ...viewListItems[colIndex].slice(itemIndex + 1),
      ],
      ...viewListItems.slice(colIndex + 1),
    ]);
  }

  function getNewValue() {
    return viewListItems.reduce((bitmask, col) => {
      bitmask += col.reduce((colBitmask, { compoundIndex, checked }) => {
        if (checked) {
          colBitmask += 1 << compoundIndex;
        }
        return colBitmask;
      }, 0);
      return bitmask;
    }, 0);
  }

  return (
    <Div position="relative">
      <Modal
        isOpen={isOpen}
        onClose={cancel}
        header={<Modal.Header title={t('t656')} />}
        fullPageSize={isSmView}
        style={{
          content: { minWidth: isSmView ? 'unset' : '640px' },
          overlay: {},
        }}
        actionButtons={[
          {
            variant: 'secondary',
            onClick: cancel,
            children: t('t45'),
            disabled: isSubmitting,
          },
          {
            variant: 'primary',
            onClick: handleSubmit,
            children: t('t266'),
            disabled: isSubmitting,
          },
        ]}
      >
        <Row>
          {viewListItems.map((col, colIndex) => (
            <Col xs={6} sm={6} md={3} key={colIndex}>
              <Div display="block" mb={theme.spacing.md} key={colIndex}>
                <H5>{item.listdata[colIndex]?.listname}</H5>
                {col.map(({ value, checked, compoundIndex }, itemIndex) => (
                  <Div
                    display="flex"
                    height="32px"
                    alignItems="center"
                    {...theme.typography.button}
                    key={itemIndex}
                  >
                    <Checkbox
                      id={`${value}-${compoundIndex}`}
                      checked={checked}
                      onChange={({ target: { checked: isChecked } }) => {
                        handleCheckboxChange(isChecked, colIndex, itemIndex);
                      }}
                    />
                    <Label
                      htmlFor={`${value}-${compoundIndex}`}
                      color={theme.palette.text.secondary}
                      {...theme.typography.label}
                      ml={`${theme.spacing.xs}px`}
                      {...(ellipsis() as any)}
                    >
                      {value && typeof value === 'object' ? value._ : value}
                    </Label>
                  </Div>
                ))}
              </Div>
            </Col>
          ))}
        </Row>
        {(isSubmitting || isError) && (
          <ConfigurationListItemEditLoadingOverlay isError={isError} />
        )}
      </Modal>
      <Button
        variant="secondary"
        small={true}
        testId="configuration-editAuth-open-button"
        onClick={handleOpen}
        disabled={!isAuthorized || isSubmitting}
        block={true}
      >
        {item.value}
      </Button>
      <Div
        display="flex"
        alignItems="center"
        position="absolute"
        top="0"
        right={['unset', `-${iconXOffset}px`]}
        left={[`-${iconXOffset}px`, 'unset']}
        height="42px"
      >
        <ConfigurationListitemEditInputAddon
          isSubmitting={isSubmitting}
          isSucceeded={isSuccess}
          isFailed={isError}
          styles={{
            inputAddon: {
              root: {
                top: isSubmitting ? '8px' : '4px',
              },
            },
          }}
        />
      </Div>
    </Div>
  );
}

export { ConfigurationListItemEditAuth };
