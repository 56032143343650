import { loadDataBase } from '@danfoss/etui-sm-xml';
import '../../../../../constants/software-versions.js';
import '../../../../../constants/software-update-unit-status.js';
import React from 'react';
import { useTranslation } from 'react-i18next';
import '../utils/delay.js';
import '../actions/fetch-read-units.js';
import { fetchUpdateSoftwareUnitStatus } from '../actions/fetch-update-software-unit-status.js';
import { executeForceResetSm800 } from '../actions/execute-force-reset-sm800.js';

function useDbImportSm800(url, user, file, startUpload, unit, installTimeout) {
  const {
    t
  } = useTranslation();
  const [progress, setProgress] = React.useState(0);
  const [loadDbSuccess, setLoadDbSuccess] = React.useState(false);
  const [forceReset, setForceReset] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [failure, setFailure] = React.useState(null);
  const handleSetProgressSuccess = (importProgress, importSuccess) => {
    setProgress(importProgress);
    setLoadDbSuccess(importSuccess);
  };
  React.useEffect(() => {
    async function forceResetSM800() {
      await executeForceResetSm800(url, user);
    }
    if (loadDbSuccess) {
      forceResetSM800();
      setProgress(50);
      setForceReset(true);
    }
  }, [loadDbSuccess]);
  React.useEffect(() => {
    const checkUnitStatus = async () => {
      const sm800Delay = 10000;
      let completed = false;
      const startTime = +new Date();
      let remaingProgress = progress;
      while (!completed) {
        // eslint-disable-next-line no-await-in-loop
        const unitStatus = await fetchUpdateSoftwareUnitStatus(url, user, sm800Delay);
        if (unitStatus === 'down') {
          if (remaingProgress !== 100) {
            remaingProgress += 5;
            setProgress(remaingProgress);
          }
          completed = false;
        } else {
          completed = true;
          setProgress(100);
          setSuccess(true);
        }
        const installTime = +new Date() - startTime;
        if (!completed && installTime > installTimeout) {
          setFailure(new Error(t('t3222')));
          completed = true;
        }
      }
    };
    if (forceReset) {
      checkUnitStatus();
    }
  }, [forceReset]);
  React.useEffect(() => {
    if (!startUpload) {
      return;
    }
    const uploadFile = async () => {
      try {
        await loadDataBase(file.name, '', file.size,
        // size -> offset
        file, user, url, unit, handleSetProgressSuccess);
      } catch (e) {
        setFailure(new Error(t('t745')));
      }
    };
    uploadFile();
  }, [startUpload]);
  return {
    progress,
    success,
    failure
  };
}

export { useDbImportSm800 };
