export { UPDATE_TYPE } from './software-update-type.js';
export { STORAGE_API_KEY, STORAGE_PROXY_KEY, STORAGE_UNITXMLURL_KEY, STORAGE_URL, STORAGE_USER_AUTHLEVEL_KEY, STORAGE_USER_LANGUAGE_KEY, STORAGE_USER_PASSWORD_KEY, STORAGE_USER_USERNAME_KEY, STORAGE_XML_BACKEND_IP_KEY, STORAGE_XML_BACKEND_PROTOCOL_KEY } from './session-storage-constants.js';
export { SOFTWARE_VERSION_PREFIX, VERSION_WITHOUT_SECURITY_SUPPORT, softwareRequirements } from './software-versions.js';
export { UpdateSoftwareUnitStatus } from './software-update-unit-status.js';

const SESSION_EXPIRY_CODE = 100;
const SECURITY_URL = `https://www.danfoss.com/en/service-and-support/downloads/dcs/adap-kool-software/ak-sm-800a/#tab-security`;
const REDIRECT_PARAMETER = '?svwlogin=true';

export { REDIRECT_PARAMETER, SECURITY_URL, SESSION_EXPIRY_CODE };
