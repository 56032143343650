import * as React from 'react';
import { Div } from '@danfoss/etui-system-elements';
import { InputAddon, InputAddonProps } from '@danfoss/etui-core';
import { AnimatedSpinner, ErrorIcon, SuccessIcon } from '@danfoss/etui-sm';

export interface ConfigurationListitemEditInputAddonProps {
  isSubmitting: boolean;
  isSucceeded: boolean;
  isFailed: boolean;
  defaultIcon?: React.ReactNode;
  styles?: {
    inputAddon?: InputAddonProps['styles'];
  };
}

export function ConfigurationListitemEditInputAddon({
  isSubmitting,
  isSucceeded,
  isFailed,
  defaultIcon = null,
  styles = {},
}: ConfigurationListitemEditInputAddonProps) {
  return (
    <InputAddon
      testId="configuration-ListEdit-inputAddOn"
      position="end"
      styles={styles.inputAddon}
    >
      {isSubmitting && (
        <Div display="block">
          <AnimatedSpinner />
        </Div>
      )}
      {defaultIcon && !isSubmitting && !isSucceeded && !isFailed && defaultIcon}
      <ErrorIcon show={!isSubmitting && isFailed} />
      <Div testId="configuration-sucess">
        <SuccessIcon show={!isSubmitting && isSucceeded} />
      </Div>
    </InputAddon>
  );
}
