import * as React from 'react';
import {
  TextInputProps,
  TextInput,
  InputAddon,
  Button,
  TimePicker,
  RELATIVE_FIELD_POSITION,
} from '@danfoss/etui-core';
import { ConfigurationListItem } from '@danfoss/etui-sm-xml';
import { buildTime } from '../ConfigurationItemContentList/utils';
import {
  AmPm,
  from12ToSaveValue,
  handle12Edit,
  handle24Edit,
  handle12Delete,
  handle24Delete,
  timeRegex12,
  timeRegex24,
} from './utils';

export type HourFormat = '12' | '24';

export type TimeInputProps = TextInputProps & {
  hourFormat: HourFormat;
  item?: ConfigurationListItem;
  externalSourceValue?: string;
  defaultValue: string; // for 12-hour format must be with AM or PM -> 01:00 AM
  defaultAmPm?: AmPm;
  onTimeChange?: ({
    value,
    display,
  }: {
    value: string;
    display: string;
  }) => void;
  onCancel?: () => void;
};

function TimeInput({
  externalSourceValue = '',
  defaultValue = '12:00',
  defaultAmPm = AmPm.AM,
  hourFormat = '12',
  onTimeChange,
  onCancel,
  disabled,
  item,
  endInputAddon,
  ...props
}: TimeInputProps) {
  const is12Format = hourFormat === '12';
  const timeRegex = is12Format ? timeRegex12 : timeRegex24;
  const input = React.useRef(null);
  const [state, setState] = React.useState({ value: defaultValue, caret: 0 });
  const [amPm, setAmPm] = React.useState(defaultAmPm);
  const token = item?.token.substring(0, 2) === '10';
  const isRelative = token && item?.type === '256';

  React.useEffect(() => {
    input.current?.setSelectionRange(state.caret, state.caret);
  }, [state]);

  React.useEffect(() => {
    if (externalSourceValue) {
      const values = externalSourceValue.split(' ');
      const time = buildTime(values[0], hourFormat);
      setAmPm(values[1] as AmPm);
      setState({
        value: time,
        caret: time.length,
      });
    }
  }, [externalSourceValue]);

  const handleOnChange = React.useCallback(
    e => {
      const { value: newValue } = e.target;
      const { value: prevValue } = state;
      let newInputValue = newValue;
      let newInputCaret = e.target.selectionEnd;

      if (newValue && !/^[0-9:\s]+$/.test(newValue))
        return setState({ value: prevValue, caret: newInputCaret - 1 });

      const newInput = parseInt(e.nativeEvent.data, 10);

      if (newValue.length >= prevValue.length) {
        const handler = is12Format ? handle12Edit : handle24Edit;
        [newInputValue, newInputCaret] = handler({
          newInputCaret,
          newInput,
          initialValue: prevValue,
        });
      } else {
        const handler = is12Format ? handle12Delete : handle24Delete;
        [newInputValue, newInputCaret] = handler({
          newInputCaret,
          newInputValue,
          initialValue: prevValue,
          newInput,
        });
      }

      if (timeRegex.test(newInputValue || prevValue)) {
        const changedValue = newInputValue || newValue;

        setState({ value: changedValue, caret: newInputCaret });
        if (onTimeChange) {
          onTimeChange({
            value: is12Format
              ? from12ToSaveValue(changedValue, amPm)
              : changedValue,
            display: `${changedValue}${is12Format ? ` ${amPm}` : ''}`,
          });
        }
      } else {
        setState({ value: prevValue, caret: newInputCaret - 1 });
      }
    },
    [amPm, is12Format, onTimeChange, state, timeRegex],
  );

  const handleOnChangeTimePicker = React.useCallback(
    e => {
      const newValue = e.substring(0, e.length - 3);
      const prevValue = state.value;
      if (newValue !== prevValue) {
        setState({ value: newValue, caret: 0 });
        if (onTimeChange) {
          onTimeChange({
            value: is12Format ? from12ToSaveValue(newValue, amPm) : newValue,
            display: newValue,
          });
        }
      } else {
        onCancel?.();
      }
    },
    [state, onTimeChange],
  );

  const handleAmPmChange = () => {
    const newAmPm = amPm === AmPm.AM ? AmPm.PM : AmPm.AM;
    setAmPm(newAmPm);
    if (onTimeChange) {
      onTimeChange({
        value: is12Format
          ? from12ToSaveValue(state.value, newAmPm)
          : state.value,
        display: `${state.value}${is12Format ? ` ${newAmPm}` : ''}`,
      });
    }
  };

  const renderAmPmButton = () => (
    <InputAddon
      testId="timeInput-amPm-inputAddOn"
      position="end"
      styles={{
        root: {
          top: `9px`,
        },
      }}
    >
      <Button
        styles={{
          root: {
            p: 0,
            width: '40px',
          },
        }}
        variant="default"
        testId="configuration-timeInput-button"
        onClick={handleAmPmChange}
        onMouseDown={event => event.preventDefault()}
        disabled={disabled}
      >
        {amPm}
      </Button>
    </InputAddon>
  );
  const minNum = parseInt(item?.min, 10);
  const maxNum = parseInt(item?.max, 10);
  const isPositiveRange = minNum >= 0 && maxNum >= 0;
  const valueWithoutSign = state.value.slice(1);

  if (isRelative) {
    return (
      <>
        <TimePicker
          testId="configuration-timeinput-timepicker"
          {...props}
          mode={isPositiveRange ? 'standard' : 'relative'}
          value={`${isPositiveRange ? valueWithoutSign : state.value}:00`}
          onChange={handleOnChangeTimePicker}
          disabled={disabled}
          isInlineEditMode={!isPositiveRange}
          labels={{
            before: '-',
            after: '+',
          }}
          relativeFieldPosition={RELATIVE_FIELD_POSITION.LEFT}
          openByDefault={true}
          onBeforeCancel={onCancel}
        />
        {endInputAddon}
      </>
    );
  }
  return (
    <TextInput
      {...props}
      elRef={input}
      disabled={disabled}
      type="text"
      value={state.value}
      testId="configuartion-time-input"
      placeholder="12:00"
      onChange={handleOnChange}
      autoComplete="off"
      endInputAddon={endInputAddon || (is12Format ? renderAmPmButton() : null)}
    />
  );
}

export default TimeInput;
