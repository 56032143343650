import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@danfoss/etui-system';
import {
  NumberInput,
  Form,
  Button,
  Tooltip,
  icons,
  Icon,
  isEmpty,
} from '@danfoss/etui-core';
import { Controller, useForm } from 'react-hook-form';
import { Div, Span } from '@danfoss/etui-system-elements';
import {
  checkIfOtherItemClicked,
  EditType,
  FORM_TEST_ID_PREFIX,
  getIsEventInForm,
  getIsValidInput,
} from './utils';
import { BaseEditProps } from './ConfigurationListItemEdit';
import { ConfigurationListitemEditInputAddon } from './ConfigurationListItemEditInputAddon';
import { useKeyboardEvent } from './hooks/useKeyboardEvent';
import { ConfigurationListItemEditDisplayValue } from './ConfigurationListItemEditDisplayValue';

export enum FloatType {
  MDT_FLOAT = 512, // floating point; basic MMI Data Type
  MDT_FLOAT_DOT1 = 513, // float;   1 decimal up to 11.1
  MDT_FLOAT_DOT2 = 514, // float;   2 decimal up to 11.2
  MDT_FLOAT_DOT3 = 515, // float;   3 decimal up to 11.3
  MDT_FLOAT_DOT4 = 516,
}

function getMinOrMax(isInt: boolean, value: string, floatType: number) {
  const initialValue = parseInt(value, 10);
  return isInt
    ? initialValue
    : floatType === FloatType.MDT_FLOAT_DOT2
    ? initialValue / 100.0
    : floatType === FloatType.MDT_FLOAT_DOT3
    ? initialValue / 1000.0
    : floatType === FloatType.MDT_FLOAT_DOT4
    ? initialValue / 10000.0
    : initialValue / 10.0;
}

function getSymbol(value: string) {
  const valueRemovedDecimal = value.replace('.', '');
  const valueRemovedMinus = valueRemovedDecimal.replace('-', '');
  const valueRemovedDigits = valueRemovedMinus.replace(/[0-9]/g, '');
  return valueRemovedDigits.trim();
}

export type ConfigurationListItemEditNumberProps = BaseEditProps & {
  editType?: EditType;
};

const ConfigurationListItemEditNumber = React.memo(
  function ConfigurationListItemEditNumber({
    index,
    name,
    item,
    editType,
    editMode,
    onEnableEdit,
    onUnsavedChanges,
    onCancel,
    onSave,
    isAuthorized,
    addonAnimatonDuration = 2000,
  }: ConfigurationListItemEditNumberProps) {
    const node = React.useRef<HTMLDivElement>();
    const theme = useTheme();
    const { t } = useTranslation();
    const [isSucceeded, setIsSucceeded] = React.useState(false);
    const {
      control,
      setValue,
      setError,
      getValues,
      errors,
      reset,
      formState,
      handleSubmit,
      clearErrors,
    } = useForm();
    const [isUndoingChanges, setIsUndoingChanges] = React.useState(false);

    useKeyboardEvent('Escape', event => {
      handleOnCancelEdit(event);
    });

    const { isSubmitted, isSubmitting, dirtyFields } = formState;
    const isEditing = editMode.includes(name);
    const isInt = editType === EditType.Num;
    const floatType = parseInt(item.type, 10);
    const initialValue = isInt ? item.iVal : item.fVal;
    const defaultValues = {
      [name]: initialValue,
    };
    const min = getMinOrMax(isInt, item.min, floatType);
    const max = getMinOrMax(isInt, item.max, floatType);
    const symbol = getSymbol(item.value);
    const error = errors[name];
    const isFailed = !!error;
    const isDirty = !!dirtyFields[name];

    const handleOnUnsavedChanges = () => {
      onUnsavedChanges({
        isOpen: true,
        onSaveChanges: () => {
          handleSubmit(handleOnSubmit)();
        },
        onCancelChanges: () => {
          setIsUndoingChanges(true);
        },
      });
    };

    const handleOnCancelEdit = (event, isOnBlur: boolean = false) => {
      if (isSubmitted || isSubmitting || isSucceeded || isFailed) {
        return;
      }

      if (isUndoingChanges) {
        reset(defaultValues);
        onCancel();
        setIsUndoingChanges(false);
        return;
      }

      if (!isOnBlur && node.current.contains(event.target)) {
        return;
      }

      const values = getValues();
      const nextValue = values[name];
      const newValueString = nextValue ? nextValue.toString() : '';
      const isFieldDirty = dirtyFields[name];

      if (isFieldDirty && initialValue !== newValueString) {
        if (isOnBlur && getIsEventInForm(event)) {
          handleSubmit(handleOnSubmit)();
        } else {
          event.preventDefault();
          if (event.currentTarget instanceof HTMLInputElement) {
            event.currentTarget.focus();
            handleOnUnsavedChanges();
          }
          if (event.currentTarget instanceof Document) {
            const { activeElement } = event.currentTarget;
            activeElement.focus();
            handleOnUnsavedChanges();
          }
        }
      } else {
        reset(defaultValues);
        const otherConfigLineName = checkIfOtherItemClicked(
          event.relatedTarget,
        );
        onCancel(otherConfigLineName);
      }
    };

    const handleEdit = () => {
      onEnableEdit(name);
    };

    const handleOnSubmit = async (data: { [key: string]: string }) => {
      const newValue = data[name];
      const newValueString = getIsValidInput(newValue)
        ? newValue.toString()
        : '';

      if (
        !initialValue ||
        (initialValue &&
          newValueString !== initialValue &&
          !isEmpty(newValueString))
      ) {
        try {
          await onSave(item, {
            ival: newValueString,
            value: item.value,
            fval: newValueString,
          });
          item.value = newValueString;
          setIsSucceeded(true);
        } catch (e) {
          setError(name, { type: 'server' });
        }
      } else {
        reset(defaultValues);
        onCancel();
      }
    };

    React.useEffect(() => {
      reset(defaultValues);
    }, [item.value, item.iVal, item.fVal]);

    React.useEffect(() => {
      if (error) {
        setTimeout(() => {
          clearErrors(name);
          reset(defaultValues);
          onCancel();
        }, addonAnimatonDuration);
      }

      if (isSucceeded) {
        setTimeout(() => {
          setIsSucceeded(false);
          onCancel();
        }, addonAnimatonDuration);
      }

      if (isUndoingChanges) {
        handleOnCancelEdit(null, false);
      }
    }, [error, isSucceeded, isUndoingChanges]);

    return isAuthorized ? (
      <Form
        onSubmit={handleSubmit(handleOnSubmit)}
        styles={{ root: { position: 'relative' } }}
        testId={`${FORM_TEST_ID_PREFIX}${name}`}
      >
        <div key={index} ref={node}>
          {isEditing ? (
            <Controller
              control={control}
              name={name}
              defaultValue={defaultValues[name]}
              render={({ onChange, value }) => (
                <NumberInput
                  size="small"
                  label={symbol}
                  isDisabled={isSubmitting || isSucceeded || isFailed}
                  name={name}
                  defaultValue={value}
                  autoFocus={isEditing}
                  value={value}
                  onChange={onChange}
                  tabIndex={0}
                  min={min}
                  max={max}
                  testId={name}
                  onBlur={event => {
                    if (isDirty) {
                      if (getIsValidInput(value)) {
                        setValue(name, value);
                        handleOnCancelEdit(event, true);
                      } else {
                        reset(defaultValues);
                      }
                    } else {
                      handleOnCancelEdit(event, true);
                    }
                  }}
                  precision={!isInt ? 1 : undefined}
                  decimalSeparator="."
                  step={isInt ? 1 : 0.1}
                  endInputAddon={
                    isSubmitting || isSucceeded || isFailed ? (
                      <ConfigurationListitemEditInputAddon
                        isSubmitting={isSubmitting}
                        isSucceeded={isSucceeded}
                        isFailed={isFailed}
                        styles={{
                          inputAddon: {
                            root: isSubmitting
                              ? {
                                  top: '9px',
                                  width: '24px',
                                  height: '24px',
                                  right: `${theme.spacing.xxs}px`,
                                }
                              : {
                                  top: '5px',
                                  width: '32px',
                                  height: '32px',
                                  right: `${theme.spacing.xxs}px`,
                                },
                          },
                        }}
                      />
                    ) : null
                  }
                  styles={{
                    label: isFailed
                      ? { color: theme.palette.error.main }
                      : isSucceeded
                      ? { color: theme.palette.success.main }
                      : {},
                    input: isFailed
                      ? { borderColor: theme.palette.error.main }
                      : isSucceeded
                      ? { borderColor: theme.palette.success.main }
                      : {},
                  }}
                />
              )}
            />
          ) : (
            <ConfigurationListItemEditDisplayValue
              onClick={handleEdit}
              onFocus={handleEdit}
              onBlur={handleOnCancelEdit}
              value={item.value}
            />
          )}
        </div>
        {isEditing && !isSubmitting && !isSucceeded && !isFailed && (
          <Div
            display="flex"
            alignItems="center"
            position="absolute"
            top="0"
            right="unset"
            left={['-30px']}
            height="42px"
          >
            <Tooltip
              placement="left"
              message={() => (
                <>
                  <Div display="inline-block">
                    {`${t('t541')}: `}
                    <strong>{min}</strong>
                  </Div>
                  <Div display="inline-block">&nbsp;</Div>
                  <Div display="inline-block">
                    {`${t('t542')}: `}
                    <strong>{max}</strong>
                  </Div>
                </>
              )}
              styles={{
                div: { lineHeight: 0 },
              }}
            >
              <Icon glyph={icons.INFO_CIRCLE} />
            </Tooltip>
          </Div>
        )}
        {isEditing && isDirty && !isSubmitting && !isSucceeded && !isFailed && (
          <Div
            display="flex"
            alignItems="center"
            position="absolute"
            top="0"
            right={['unset', 'unset', '-62px']}
            left={['-82px', '-82px', 'unset']}
            height="42px"
          >
            <Button
              variant="tertiary"
              testId="configuration-undo-number-button"
              onMouseDown={() => setIsUndoingChanges(true)}
              small={true}
            >
              {t('t964')}
            </Button>
          </Div>
        )}
      </Form>
    ) : (
      <Span>{item.value}</Span>
    );
  },
);

export { ConfigurationListItemEditNumber };
