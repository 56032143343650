import { TreeItem } from '@danfoss/etui-core';
import { Device } from '@danfoss/etui-sm-xml';

export type ScheduleType =
  | 'Circuit night setbacks'
  | 'Suction night setbacks'
  | 'Store'
  | 'Refrigeration'
  | 'Lighting'
  | 'hvac'
  | 'Shutdown'
  | 'Defrost'
  | 'Case Lights'
  | 'Night Setback'
  | 'Case Lighting'
  | 'Coord Defrost'
  | 'Adaptive Defrost';

export enum ScheduleTypes {
  CIRCUIT_NIGHT_SETBACKS = 'Circuit night setbacks',
  SUCTION_NIGHT_SETBACKS = 'Suction night setbacks',
  SHUTDOWN = 'Shutdown',
  DEFROST = 'Defrost',
  CASE_LIGHTS = 'Case Lights',
  NIGHT_SETBACK = 'Night Setback',
  LIGHTING = 'Lighting',
  CASE_LIGHTING = 'Case Lighting',
  ADAPTIVE_DEFROST = 'Adaptive Defrost',
  COORD_DEFROST = 'Coord Defrost',
  STORE_HOURS = 'store-hours',
  STORE_HOLIDAYS = 'store-holidays',
  REF_DEFROST = 'Refrigeration Defrost',
  REF_NON_DEFROST = 'Refrigeration Non Defrost',
  HVAC = 'hvac',
  ZONE = 'ZONE',
}

export enum QuerySchedTypes {
  STORE = 'store',
  STORE_HOURS = 'store-hours',
  REFRIGERATION = 'refrigeration',
  LIGHTING = 'lighting',
  HVAC = 'hvac',
  CIRCUIT_NIGHT_SETBACKS = 'circuit-night-setbacks',
  SUCTION_NIGHT_SETBACKS = 'suction-night-setbacks',
  SHUTDOWN = 'shutdown',
  DEFROST = 'defrost',
  CASE_LIGHTS = 'case-lights',
  NIGHT_SETBACK = 'night-setback',
}

export enum ScheduleIds {
  case_defrost_sch_ids = 'case_defrost_sch_ids',
  case_night_setback_sch_ids = 'case_night_setback_sch_ids',
  case_shutdown_sch_ids = 'case_shutdown_sch_ids',
  case_light_sch_ids = 'case_light_sch_ids',
  night_setback_sch_ids = 'night_setback_sch_ids',
}

export enum AkioSchedules {
  akio_defrost_schedules = 'akio_defrost_schedules',
  akio_schutdown_schedules = 'akio_shutdown_schedules',
}

export enum ScheduleTabs {
  TABLE_VIEW = 'tableview',
  CALENDAR_VIEW = 'calendarview',
}

export enum ScheduleConstant {
  SCHED_VIEW_DEFROST = 0,
  SCHED_VIEW_NIGHTSB = 1,
  SCHED_VIEW_SHUTDOWN = 2,
  SCHED_VIEW_CASE_LIGHTS = 4,
}

export enum ScheduleTypeConstant {
  SCHED_CUSTOM = 0,
  SCHED_CASE_LIGHTS = 1,
  SCHED_NIGHTSB = 2, // Circuit or Suction ?
  SCHED_SHUTDOWN = 3,
  SCHED_DEFROST = 4,
  SCHED_COORD_DEFROST = 5,
  SCHED_ADAPTIVE_DEFROST = 6,
}

export enum TimeConstant {
  MAX_TIME = '11:30 PM',
  THIRTY_MIN = 30,
  FIFTY_NINE = 59,
  NON_DEFROST_MAX_HOUR = 23,
  ZERO = '00',
  TWELVE = 12,
  TIMEFORMAT = 0,
  TWENTY_THREE = 23,
  STR_TWELVE = '12',
  MIDNIGHT_12 = '12:00 AM',
  MIDNIGHT_24 = '00:00',
}

export type ScheduleFieldName =
  | 'circuitNightSetbacksSchedules'
  | 'SuctionNightSetbacksSchedules'
  | 'defrostSchedules'
  | 'lightsSchedules'
  | 'nightSchedules'
  | 'shutdownSchedules';

export interface ScheduleTreeItem extends TreeItem {
  comboindex?: number;
  devices?: ScheduleTreeItem[];
  items: ScheduleTreeItem[];
  holidays?: string;
  weekdays?: string;
  schedtype?: ScheduleConstant;
  shutdown_sch_id?: string;
  defrost_sch_id?: string;
  case_light_sch_id?: string;
  night_setback_sch_id?: string;
  defrostSchedules?: ScheduleDetail[];
  lightsSchedules?: ScheduleDetail[];
  nightSchedules?: ScheduleDetail[];
  shutdownSchedules?: ScheduleDetail[];
  shutdown_sched_cnt?: string;
  shutdown_schedules?: {
    days: string;
    hdays: string;
    stop: string | string[];
    start: string | string[];
  };
  schedules?: {
    defr_days: string;
    defr_hdays: string;
    num_scheds: string;
    stop?: string | string[];
    start?: string | string[];
  };
  defrost_type?: string;
  interval?: number;
  defr_term?: string;
  ctrl_val?: string;
}

export type ScheduleItem = ScheduleTreeItem & Device;

interface ScheduleDetail {
  count?: number;
  type?: string;
  defr_interval?: number;
  holidays: string;
  on_time: string;
  off_time?: string;
  weekdays: string;
}

interface Schedules {
  def_interval: string;
  def_start_time: string;
  desc: string;
  enable: string;
  id: string;
  schedType: string;
  schedules: {
    sch_number: string;
    sch_detail: ScheduleDetail[];
  };
  stagger: string;
  status: string;
  usage: ScheduleType | ScheduleTypes.CASE_LIGHTS;
}

export interface ScheduleDevice {
  addr?: string;
  arg1: string;
  combo: string;
  host: string;
  name: string;
  nodetype: string;
  schedules: Schedules;
  stype: string;
  type: string;
  usage?: ScheduleType | ScheduleTypes.CASE_LIGHTS;
}

interface Hvacs {
  hvac: HvacUnit[];
  total: string;
}

export interface HvacUnit {
  addr?: string;
  ahindex: string;
  alarm: string;
  bpidx: string;
  hvac_type: string;
  mod: string;
  name: string;
  node: string;
  nodetype: string;
  point: string;
  status: string;
  units: string;
  value: {
    units_index: string;
    _: string;
  };
  device_id: string;
}

export interface HvacUnitsResponse {
  action: string;
  hvacs: Hvacs;
  error: string;
  index: string;
  password: string;
  storeview_only: string;
  total: string;
  unit_name: string;
  user: string;
  version: string;
}

interface LightingResponse {
  action: string;
  hvacs: Hvacs;
  error: string;
  index: string;
  lang: string;
  password: string;
  storeview_only: string;
  total: string;
  unit_name: string;
  time_format: string;
  user: string;
  version: string;
  relays: {
    count: string;
    relay: Relay;
  };
  run_times: RunTimes;
  zone: Zone;
}

interface Relay {
  name: string;
  index: string;
  status: string;
}

interface RunTimes {
  today: string;
  yesterday: string;
  total_hrs: string;
  total_maint: string;
}

interface Zone {
  name: string;
  type: string;
  status: string;
  alarm: string;
  schedules: {
    active: string;
    count: string;
    schedule: LightningSchedule[];
  };
}

interface LightningSchedule {
  type: string;
  index: string;
  on: string;
  off: string;
  days: string;
  holidays: string;
}

interface Schedule_Stage {
  index: string;
  postdelay: string;
  predelay: string;
  range: string;
  stage: string;
  status: string;
  target: string;
}

export interface HvacSchedule {
  usage: string;
  name: string;
  days: string;
  holidays: string;
  index: string;
  start: string;
  stop: string;
  type: string;
}

interface HvacNightSetBack {
  num_scheds: string;
  offset: string;
  schedule: any | HvacSchedule[];
  value: string;
}

export interface Cooling_Stages {
  total: string;
  cooling_stage: Schedule_Stage;
  nightsetback: HvacNightSetBack;
}

export interface Heat_Reclaims {
  total: string;
  heat_reclaim: Schedule_Stage;
  nightsetback: HvacNightSetBack;
}

export interface Aux_Heats {
  total: string;
  aux_heat: Schedule_Stage;
  nightsetback: HvacNightSetBack;
}

export interface HvacUnitScheduleRes {
  action: string;
  addr: string;
  ahindex: string;
  alarm: string;
  aux_heats: Aux_Heats;
  bpidx: string;
  cooling_stages: Cooling_Stages;
  dehumid_type: Dehumid_Type;
  errors: string;
  heat_reclaims: Heat_Reclaims;
  hvac_type: string;
  lang: string;
  mod: string;
  name: string;
  node: string;
  nodetype: string;
  password: string;
  point: string;
  status: string;
  storeview_only: string;
  units: string;
  user: string;
  value: { _: string; units_index: string };
  version: string;
  dehumidification?: DehumidHvacSchedule;
}

interface Dehumid_Type {
  dehumid_control: Dehumid_Control;
  stages: Dehumid_Stage[];
  type: string;
  type_id: string;
  error: string;
}

interface Dehumid_Stage {
  range: string;
  stage: Dehumid_Stage_Detail;
  unit_index: string;
  units: string;
}

interface Dehumid_Stage_Detail {
  index: string;
  post_delay: string;
  pre_delay: string;
  target: string;
}

interface Dehumid_Control {
  control_id: string;
  _: string;
}

export interface DehumidHvacSchedule {
  action: string;
  dehumidify: Dehumidify;
  detail: string;
  eds_only: string;
  error: string;
  full: string;
  lang: string;
  password: string;
  storeview_only: string;
  tag_id: string;
  user: string;
  version: string;
}

interface Dehumidify {
  nightsetback: DehumidNightsetback;
  offset: DehumidOffset;
  tag_id: string;
  type: Tag;
  type_display: Tag;
  type_list_id: Tag;
}

interface DehumidSchedulesList {
  list: DehumidSchedule;
  tag_id: string;
  total: Tag;
}

interface DehumidSchedule {
  schedule: DehumidScheduleDetail[];
}

interface DehumidScheduleDetail {
  days: Tag;
  holidays: Tag;
  list_id: Tag;
  start: Tag;
  stop: Tag;
  tag_id: string;
  type: Tag;
  type_display: Tag;
}

interface DehumidNightsetback {
  offset: DehumidOffset;
  schedules: DehumidSchedulesList;
  status: Tag;
  status_display: Tag;
  status_list_id: Tag;
  tag_id: string;
  use: Tag;
  use_display: Tag;
  use_list_id: Tag;
}

interface DehumidOffset {
  eng_units: Tag;
  eng_units_display: Tag;
  setpoint: Tag;
  tag_id: string;
}

interface Tag {
  max: string;
  min: string;
  rw: string;
  tag_id: string;
  _: string;
}

interface ScheduleRes {
  action: string;
  device: ScheduleDevice[];
  error: string;
  index: string;
  lang: string;
  password: string;
  storeview_only: string;
  time_format: string;
  total: string;
  unit_name: string;
  user: string;
  version: string;
}

export const scheduleDays = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];
export const scheduleDaysWidth = [10, 10, 20, 25, 35, 35, 50];

export const scheduleNonDefrostDaysWidth = [10, 10, 20, 20, 20, 35, 50];

export const scheduleLightingDaysWidth = [5, 5, 10, 15, 15, 25, 30];

export interface StoreHours {
  index: string;
  user: string;
  password: string;
  lang: string;
  action: string;
  storeview_only: string;
  version: string;
  time_format: string;
  error: string;
  currenttime: CurrentTime;
  days: StoreHourDays;
}

interface CurrentTime {
  time: string;
  timezone: string;
  daylightsavings: string;
  spring: Season;
  fall: Season;
}

interface Season {
  month: string;
  week: string;
  day: string;
  time: string;
}

export interface StoreHoursSchedule {
  value: string;
  id: string;
  open: OpenClose;
  close: OpenClose;
}

export interface OpenClose {
  hour: string;
  minute: string;
  units: string;
}

export interface StoreHourDays {
  count: string;
  day: StoreHoursSchedule[];
}

export interface RefrigerationSchedules {
  id?: string;
  name: string;
  cases?: ScheduleItem[];
  usage: string;
  type: string;
  on_time: string;
  off_time: string;
  weekdays: string;
  days?: string;
  holidays: string;
  on?: string;
  off?: string;
  index?: string;
  open?: OpenClose;
  close?: OpenClose;
}

export interface StoreHourSchedules {
  id: string;
  name: string;
  usage: string;
  type: string;
  on_time: string;
  off_time: string;
  weekdays: string;
  holidays: string;
  on?: string;
  off?: string;
}

export enum BaseEventItemOptions {
  margin = 20,
  width = '10px',
}

export type ScheduleResponses =
  | StoreHours
  | ScheduleRes
  | HvacUnitsResponse
  | LightingResponse;

export type OverrideSchedules = StoreHoursSchedule[] | RefrigerationSchedules[];
export interface AkioDefrostSchedules {
  defr_days: string;
  defr_hdays: string;
  interval: string;
  num_scheds: string;
  start: string[];
  defrost_type: string;
}

export interface AkioShutdownSchedules {
  start: string;
  stop: string;
  days: string;
  hdays: string;
  defrost_type: string;
  // name: string;
}

export const AkioSheduleTypes = [
  'None',
  'Hot Gas',
  'Time Off',
  'Air',
  'Electric',
];

export const Weekdays = ['A', 'F', 'R', 'W', 'T', 'M', 'S'];
export const Holidays = ['8', '7', '6', '5', '4', '3', '2', '1'];

export interface Refrigerations {
  glyph: string;
  id: string;
  name: string;
  items: PackItem[];
}

export interface PackItem {
  id: string;
  name: string;
  items: SuctionItem[];
}

export interface SuctionItem {
  akioDefrostSchedules: AkioDefrostSchedules[];
  akioShutdownSchedules: AkioShutdownSchedules[];
  id: string;
  name: string;
  totalCase: number;
  totalSchedules: number;
  scheduleDevices: ScheduleDevicesTypes;
  scheduleIds: ScheduleId;
}

interface ScheduleId {
  case_lighting_sch_ids: string[];
  defrost_sch_ids: string[];
  night_setback_sch_ids: string[];
  shutdown_sch_ids: string[];
}

interface ScheduleDevicesTypes {
  defrost_devices: SuctionItem[];
  night_setback_devices: SuctionItem[];
  shutdown_devices: SuctionItem[];
  case_lighting_devices: SuctionItem[];
}

export interface ScheduleItemGraphicOptions {
  id: string;
  color: string;
  offset: number;
}

export interface ScheduleColumn {
  title: string;
  key: string;
  dataIndex: string;
  width: string;
  sorter?: (a: ScheduleItem, b: ScheduleItem) => number;
  render?: (...args: any) => string;
}
