import * as React from 'react';
import {
  Button,
  Modal,
  Row,
  Col,
  Tooltip,
  ModalProps,
} from '@danfoss/etui-core';
import { Div } from '@danfoss/etui-system-elements';
import { useTranslation } from 'react-i18next';
import { useModal } from '@danfoss/etui-sm';
import { ConfigurationListItem as ConfigurationListItemProp } from '@danfoss/etui-sm-xml';
import { useConfigurationModals } from '../../context';
import { useFlash } from './hooks/useFlash';
import { BaseEditProps } from './ConfigurationListItemEdit';
import { ConfigurationListitemEditInputAddon } from './ConfigurationListItemEditInputAddon';
import { useConfigAuth } from './hooks/config-auth';

const USERS_TABLE_ADDR = '20085';

const UNDELETABLE_FILE_FORMATS = ['.json'];
function ConfirmationDialog({
  onSave,
  onClose,
  isOpen,
  toggleModals,
  item,
}: Partial<ModalProps> & {
  onSave: () => void;
  toggleModals: (modalName: string) => void;
  item: ConfigurationListItemProp;
}) {
  const { t } = useTranslation();
  const handleClose = e => {
    toggleModals(item.name + item.li);
    onClose(e);
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      header={<Modal.Header title={t('t214')} />}
      actionButtons={[
        {
          children: t('t45'),
          variant: 'secondary',
          onClick: handleClose,
        },
        { children: t('t44'), onClick: onSave, variant: 'primary' },
      ]}
    >
      <Row>
        <Col xs={12}>
          <Div>{t('t186')}</Div>
        </Col>
      </Row>
    </Modal>
  );
}
function ConfigurationListItemEditAction({
  item,
  isAuthorized,
  isEditable,
  onSave,
  removeBtn = false,
}: BaseEditProps & {
  removeBtn?: boolean;
}) {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [startSuccessFlash, isSuccess] = useFlash(2000);
  const [startErrorFlash, isError] = useFlash(2000);
  const isConfirmRequired = useConfigAuth(item);
  const { location } = window;
  const { openModals, toggleModals } = useConfigurationModals();
  const isUserPage = location.hash.includes(USERS_TABLE_ADDR);
  const isFirstItem = item.li === '0';

  const handleSave = React.useCallback(async () => {
    hideModal(); // close immediately. pending/error state will be shown on trigger button
    setIsSubmitting(true);
    try {
      await onSave(item, {
        value: removeBtn ? item.value : '',
        ival: removeBtn ? '1' : '',
        fval: '',
      });
      startSuccessFlash();
    } catch (e) {
      startErrorFlash();
    } finally {
      setIsSubmitting(false);
      if (isConfirmRequired && !(isFirstItem && isUserPage)) {
        toggleModals(item?.name + item?.li);
      }
    }
  }, [item, onSave]);

  const [showModal, hideModal] = useModal(ConfirmationDialog, {
    onSave: handleSave,
    toggleModals,
    item,
  });

  const isActive = isAuthorized && isEditable;

  const handleShowModal = () => {
    const { name, li } = item;
    showModal();
    toggleModals(name + li);
  };
  React.useEffect(() => {
    const { name, li } = item;
    if (openModals.includes(name + li)) showModal();
  }, [item]);
  const undeletableFile =
    removeBtn &&
    UNDELETABLE_FILE_FORMATS.some(format =>
      item.name.trimStart().split(' ')[0].endsWith(format),
    );

  return (
    <>
      <Div position="relative">
        <Tooltip
          message={t('t3326')}
          placement="left"
          disableToolTip={!undeletableFile}
          styles={{ root: { whiteSpace: 'initial' } }}
        >
          <Button
            variant="secondary"
            small={true}
            block={true}
            testId="configuration-tooltip-save-button"
            onClick={
              (!isFirstItem && isUserPage) || isConfirmRequired || removeBtn
                ? handleShowModal
                : handleSave
            }
            disabled={!isActive || isSubmitting || undeletableFile}
          >
            {removeBtn ? t('t487') : item.name}
          </Button>
        </Tooltip>
        <ConfigurationListitemEditInputAddon
          isSubmitting={isSubmitting}
          isSucceeded={isSuccess}
          isFailed={isError}
          styles={{
            inputAddon: {
              root: {
                top: isSubmitting ? '8px' : '4px',
                right: `-28px`,
                width: '24px',
                height: '24px',
              },
            },
          }}
        />
      </Div>
    </>
  );
}

export { ConfigurationListItemEditAction };
