import { defaultTheme } from '@danfoss/etui-themes';
import styled from 'styled-components';
import { Brush } from 'recharts';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  padding: ${defaultTheme.spacing.md}px;
`;

export const ChartTitle = styled.p`
  margin: 0;
  padding: 0;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  text-align: center;
  font-size: ${defaultTheme.typography.h4};
`;

export const ProgressIndicatorDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${defaultTheme.spacing.lg}px;
`;

export const ChartContainer = styled.div`
  align-items: center;
  display: flex;
  height: 300px;
  width: '100%';
`;

export const CustomBrush = styled(Brush)`
  margin-top: ${defaultTheme.spacing.md}px;
  margin: ${defaultTheme.spacing.md}px;
`;
