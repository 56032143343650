import { Alarm } from '@danfoss/etui-sm-xml';

export const filterAlarms = (
  status: string,
  stateKey: keyof Alarm,
  stateValue: string | number,
  alarmResult,
): Alarm[] => {
  const alarmData: Alarm[] = alarmResult;
  return alarmData.filter(
    alarm =>
      alarm.status?.toLowerCase() === status &&
      alarm[stateKey].toString() === stateValue.toString(),
  );
};
