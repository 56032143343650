import { saveAs } from 'file-saver';
import { TFunction } from 'i18next';
import { User, Unit } from '@danfoss/etui-sm-xml';
import { filterAlarms } from '../components/AlarmsExportModal/utils/filterAlarm';
import { fetchAllAlarmsByUnits } from './fetch-all-alarms-by-units';

const getCheckedAlarmsRefs = (
  data: {
    active: boolean;
    acked: boolean;
    cleared: boolean;
  },
  alarmRefs: { active: string[]; acked: string[]; cleared: string[] },
) => {
  const checkedAlarmsRefs = [];
  Object.keys(data).map(key =>
    data[key]
      ? checkedAlarmsRefs.push(alarmRefs[key])
      : checkedAlarmsRefs.filter(e => e !== key),
  );
  return checkedAlarmsRefs.flat();
};

const getIndiviualAlarmRows = (alarms, alarmType, t: TFunction) => {
  let rows = alarms?.map(
    ({
      name,
      deviceName,
      addr,
      setting,
      current_value,
      occurDate,
      occurTime,
      ack,
      cleared_state,
      action,
      action_number,
      acknowledgement,
      clearDate,
      clearTime,
    }) => [
      name,
      deviceName,
      addr,
      setting,
      current_value?._ || '',
      `${occurDate} ${occurTime}`,
      Number(ack) ? acknowledgement : t('t299'),
      Number(cleared_state) ? `${clearDate} ${clearTime}` : '',
      action,
      action_number,
    ],
  );

  const titleRow = [alarmType];
  rows = [titleRow, ...rows];
  return rows;
};

export async function fetchGenerateAlarmsCsvFile({
  active,
  acked,
  cleared,
  alarmRefs,
  user,
  units,
  xmlBackendURL,
  t,
}: {
  active: boolean;
  acked: boolean;
  cleared: boolean;
  alarmRefs: { active: string[]; acked: string[]; cleared: string[] };
  t: TFunction;
  xmlBackendURL: string;
  user: User;
  units: Unit[];
}) {
  const selectedAlarmRefs = getCheckedAlarmsRefs(
    { active, acked, cleared },
    alarmRefs,
  );

  const alarms = await fetchAllAlarmsByUnits(
    xmlBackendURL,
    selectedAlarmRefs,
    user,
    units,
  );

  const header = [
    t('t132'),
    t('t35'),
    t('t57'),
    t('t163'),
    t('t133'),
    t('t8'),
    t('t306'),
    t('t11'),
    t('t304'),
    t('t305'),
  ];

  const titleRow = [t('t152')];
  const emptyRow = [];

  let csvRows = [];

  if (active) {
    const activeAlarms = filterAlarms('active', 'acked_state', '0', alarms);
    csvRows.push(getIndiviualAlarmRows(activeAlarms, t('t0'), t));
    csvRows.flat();
  }
  if (acked) {
    const ackedAlarms = filterAlarms('active', 'ack', '1', alarms);
    csvRows.push(getIndiviualAlarmRows(ackedAlarms, t('t191'), t));
    csvRows.flat();
  }
  if (cleared) {
    const clearedAlarms = filterAlarms('cleared', 'cleared_state', '1', alarms);
    csvRows.push(getIndiviualAlarmRows(clearedAlarms, t('t1'), t));
    csvRows.flat();
  }

  csvRows = csvRows.flat();

  const csvFile =
    csvRows &&
    [titleRow, emptyRow, header, ...csvRows].map(row => row.join()).join('\n');

  // Saving URLs
  saveAs(`data:text/csv;charset=utf-8,${encodeURI(csvFile)}`, `alarms.csv`);
}
