import * as React from 'react';
import { useTheme } from '@danfoss/etui-system';
import { Button, Spinner } from '@danfoss/etui-core';
import { useSecurityBrowserCertificate } from '@danfoss/etui-sm/modules';

export function SettingsBrowserCertificateModalActions() {
  const theme = useTheme();
  const { activeTab, buttonPropsByTab } = useSecurityBrowserCertificate();

  const buttonProps = buttonPropsByTab[activeTab];

  return buttonProps ? (
    <Button testId="settings-modal-button" block={true} {...buttonProps}>
      {buttonProps.disabled && (
        <Spinner
          size={0}
          isTransparent={true}
          styles={{ root: { pr: theme.spacing.sm } }}
        />
      )}
      {buttonProps.children}
    </Button>
  ) : null;
}
