import { User, Unit, isCurrentlySelectedUnit } from '@danfoss/etui-sm-xml';
import { fetchAllAlarmRefsByUnit } from './fetch-all-alarm-refs-by-unit';

export type AllAlarmRefs = {
  active: string[];
  acked: string[];
  cleared: string[];
};

export async function fetchAllAlarmRefsByUnits([
  url,
  user,
  units,
  skipSessionUpdate = false,
]: [string, User, Unit[], boolean?]) {
  const data = await Promise.all(
    units.map(unit => {
      return fetchAllAlarmRefsByUnit(
        url,
        user,
        unit,
        skipSessionUpdate && isCurrentlySelectedUnit(unit),
      );
    }),
  );

  const allAlarmRefs: AllAlarmRefs = data.reduce(
    (result, { active, acked, cleared }) => {
      result.active = [...result.active, ...active];
      result.acked = [...result.acked, ...acked];
      result.cleared = [...result.cleared, ...cleared];
      return result;
    },
    { active: [], acked: [], cleared: [] },
  );

  return allAlarmRefs;
}
