import './get-measurements-by-unit.js';
import { getFormattedDate } from './get-formatted-date.js';
import { getFormattedTime } from './get-formatted-time.js';

const getFormattedDateAndTime = (formats, milliseconds) => {
  const {
    dateFormat,
    timeFormat
  } = formats;
  const formattedDate = getFormattedDate(dateFormat, milliseconds);
  const formattedTime = getFormattedTime(timeFormat, milliseconds);
  return `${formattedDate} ${formattedTime}`;
};

export { getFormattedDateAndTime };
