import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  TextInput,
  SelectInput,
  Form,
  Button,
  Spinner,
  SpinnerSize,
} from '@danfoss/etui-core';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@danfoss/etui-system';
import { Div } from '@danfoss/etui-system-elements';
import {
  ADDRESS_FIELD_NAMES,
  MAX_VALUES,
  MIN_VALUES,
} from '@danfoss/etui-sm-xml';
import { FieldRangeToolTip } from '../FieldRangeTooltip';
import { ConfigurationListItemEditLoadingOverlay } from './ConfigurationListItemEditLoadingOverlay';
import { NodeTypes } from './utils/address-utils';
import { getIsFieldEditing } from './utils';

export function FormFooter({
  onCancel,
  isLoading,
  isSaveDisabled,
}: {
  onCancel: () => void;
  isLoading: boolean;
  isSaveDisabled?: boolean;
}) {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Div
      display="flex"
      justifyContent="flex-end"
      mt="auto"
      alignItems="center"
      mb={`${theme.spacing.md}px`}
    >
      <Button
        variant="tertiary"
        styles={{ root: { mr: `${theme.spacing.md}px` } }}
        testId="configuration-edit-cancel-button"
        onClick={onCancel}
      >
        {t('t45')}
      </Button>
      {isLoading ? (
        <Spinner size={SpinnerSize.xSmall} />
      ) : (
        <Button
          type="submit"
          variant="primary"
          testId="configuration-edit-save-button"
          disabled={isSaveDisabled}
        >
          {t('t266')}
        </Button>
      )}
    </Div>
  );
}

const FormContent = ({ register, name, value }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <>
      <TextInput
        // @ts-ignore
        elRef={register}
        name="name"
        testId="configuration-editAddr-nameOne-input"
        label={t('t76')}
        defaultValue={name}
        styles={{
          root: { mb: `${theme.spacing.md}px`, width: '50%' },
        }}
      />
      <TextInput
        // @ts-ignore
        elRef={register}
        type="number"
        name="value"
        testId="configuration-editAddr-value-input"
        label={t('t77')}
        defaultValue={value}
        styles={{
          root: { mb: `${theme.spacing.md}px`, width: `${50 / 3}%` },
        }}
      />
    </>
  );
};

export function AddressFormGeneric({ item, onCancel, onSave, isLoading }: any) {
  const theme = useTheme();
  const { t } = useTranslation();
  const { name = '', addressLine: { value = '' } = {} } = item || {};

  const { register, handleSubmit, formState } = useForm({
    defaultValues: {
      name,
      value,
    },
  });

  const { isSubmitting } = formState;
  const isSaveDisabled = !item;

  return (
    <Form
      onSubmit={item ? handleSubmit(onSave) : undefined}
      styles={{
        root: {
          mx: `${theme.spacing.md}px`,
          flex: '1 1 0',
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      {!item ? (
        <>
          <p>{t('t3402')}</p>
        </>
      ) : (
        <FormContent register={register} name={name} value={value} />
      )}
      <FormFooter
        onCancel={onCancel}
        isLoading={isLoading}
        isSaveDisabled={isSaveDisabled}
      />
      {isSubmitting && <ConfigurationListItemEditLoadingOverlay />}
    </Form>
  );
}

export function AddressForm({
  item,
  ioTypes,
  onCancel,
  onSave,
  isLoading,
}: any) {
  const theme = useTheme();
  const { t } = useTranslation();
  const { optionsLine: options, addressLine: address, name } = item;
  const { node, mod, point, iotype, listdata } = address;
  const [editMode, setEditMode] = React.useState<string[]>([]);

  const onEnableFieldEditMode = (name: string) => {
    setEditMode([name]);
  };

  const onDisableFieldEditMode = () => {
    setEditMode([]);
  };
  const types = React.useMemo(
    () =>
      options?.listdata.map(({ _: label, datavalue: value }) => ({
        value,
        label,
      })) || [],
    [item],
  );
  const calculations = React.useMemo(
    () =>
      listdata
        .filter(({ node: value }) => value !== undefined)
        .map(({ node: value, _: label }) => ({
          label,
          value,
        })),
    [item],
  );
  const type =
    (options && types?.find(({ value }) => options.iVal === value)) || types[0];
  const io = ioTypes.find(({ value }) => iotype === value) || ioTypes[0];
  const calc =
    calculations.find(({ value }) => point === value) || calculations[0];
  const { register, handleSubmit, watch, control, formState } = useForm({
    defaultValues: {
      name,
      type,
      io,
      calc,
      node,
      mod,
      point,
    },
  });
  const { value: watchIo } = watch('io');
  const { isSubmitting } = formState;

  return (
    <Form
      onSubmit={handleSubmit(onSave)}
      styles={{
        root: {
          mx: `${theme.spacing.md}px`,
          flex: '1 1 0',
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <TextInput
        // @ts-ignore
        elRef={register}
        name="name"
        testId="configuration-editAddr-nameTwo-input"
        label={t('t76')}
        defaultValue={name}
        styles={{
          root: { mb: `${theme.spacing.md}px`, width: '50%' },
        }}
      />
      <Controller
        control={control}
        name="type"
        render={({ onChange, value }) => (
          // @ts-ignore
          <SelectInput
            onChange={onChange}
            value={value}
            label={t('t34')}
            styles={{
              root: { mb: `${theme.spacing.md}px`, width: '25%' },
            }}
            options={types}
          />
        )}
      />
      <Controller
        control={control}
        name="io"
        render={({ onChange, value }) => (
          // @ts-ignore
          <SelectInput
            onChange={onChange}
            value={value}
            label={t('t56')}
            styles={{
              root: {
                mb: `${theme.spacing.md}px`,
                width: '25%',
              },
            }}
            options={ioTypes}
          />
        )}
      />
      {watchIo === NodeTypes.NODE_TYPE_CALC ? (
        calculations.length > 0 && (
          <Controller
            control={control}
            name="calc"
            render={({ onChange, value }) => (
              // @ts-ignore
              <SelectInput
                onChange={onChange}
                value={value}
                label={t('t167')}
                styles={{
                  root: {
                    mb: `${theme.spacing.md}px`,
                    width: '25%',
                  },
                }}
                options={calculations}
              />
            )}
          />
        )
      ) : (
        <>
          <Div style={{ display: 'flex' }}>
            <TextInput
              // @ts-ignore
              elRef={register}
              name={ADDRESS_FIELD_NAMES.NODE}
              testId="configuration-editAddr-node-input"
              type="number"
              label={t('t23')}
              defaultValue={node}
              onFocus={() => onEnableFieldEditMode(ADDRESS_FIELD_NAMES.NODE)}
              onBlur={onDisableFieldEditMode}
              min={MIN_VALUES.MIN_LIMIT_VALUE}
              max={MAX_VALUES.NODE}
              styles={{
                root: {
                  mb: `${theme.spacing.md}px`,
                  width: `${50 / 3}%`,
                },
              }}
            />
            {getIsFieldEditing(editMode, ADDRESS_FIELD_NAMES.NODE) && (
              <FieldRangeToolTip field={ADDRESS_FIELD_NAMES.NODE} />
            )}
          </Div>
          <Div style={{ display: 'flex' }}>
            {watchIo === NodeTypes.NODE_TYPE_RCO && (
              <TextInput
                // @ts-ignore
                elRef={register}
                name={ADDRESS_FIELD_NAMES.MOD}
                testId="configuration-editAddr-mod-input"
                defaultValue={mod}
                type="number"
                label={t('t24')}
                min={MIN_VALUES.MIN_LIMIT_VALUE}
                max={MAX_VALUES.MOD}
                onFocus={() => onEnableFieldEditMode(ADDRESS_FIELD_NAMES.MOD)}
                onBlur={onDisableFieldEditMode}
                styles={{
                  root: {
                    mb: `${theme.spacing.md}px`,
                    width: `${50 / 3}%`,
                  },
                }}
              />
            )}
            {getIsFieldEditing(editMode, ADDRESS_FIELD_NAMES.MOD) && (
              <FieldRangeToolTip field={ADDRESS_FIELD_NAMES.MOD} />
            )}
          </Div>
          <Div style={{ display: 'flex' }}>
            <TextInput
              // @ts-ignore
              elRef={register}
              name={ADDRESS_FIELD_NAMES.POINT}
              testId="configuration-editAddr-point-input"
              type="number"
              defaultValue={point}
              min={MIN_VALUES.MIN_LIMIT_VALUE}
              max={MAX_VALUES.POINT}
              onFocus={() => onEnableFieldEditMode(ADDRESS_FIELD_NAMES.POINT)}
              onBlur={onDisableFieldEditMode}
              label={t('t35')}
              styles={{
                root: {
                  mb: `${theme.spacing.md}px`,
                  width: `${50 / 3}%`,
                },
              }}
            />
            {getIsFieldEditing(editMode, ADDRESS_FIELD_NAMES.POINT) && (
              <FieldRangeToolTip field={ADDRESS_FIELD_NAMES.POINT} />
            )}
          </Div>
        </>
      )}
      <FormFooter onCancel={onCancel} isLoading={isLoading} />
      {isSubmitting && <ConfigurationListItemEditLoadingOverlay />}
    </Form>
  );
}
