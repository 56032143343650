import {
  User,
  getUnitUrl,
  Unit,
  fetchAlarmRefsByType,
} from '@danfoss/etui-sm-xml';
import { SESSION_EXPIRY_CODE } from '@danfoss/etui-sm';
import { AlarmType } from '../constants';

export async function fetchAlarmRefsByUnit(
  url: string,
  alarmType: AlarmType,
  user: User,
  unit: Unit,
  skipSessionUpdate?: boolean,
) {
  try {
    const unitAlarmRefs = await fetchAlarmRefsByType(
      getUnitUrl(url, unit),
      alarmType,
      user,
      skipSessionUpdate,
    );
    return unitAlarmRefs.map(
      unitAlarmRef => `${unit.unit_addr}-${unitAlarmRef}`,
    );
  } catch (e) {
    if (+e.code === SESSION_EXPIRY_CODE) throw e;
    else return [] as string[];
  }
}
