import '@danfoss/etui-sm-xml';
import '@danfoss/etui-core';
import { softwareRequirements } from '../../../constants/software-versions.js';
import { getSoftwareVersionPrefix } from '../../../utils/get-software-version-prefix.js';
import '../../../utils/get-is-misc-device.js';
import { getOlderSoftwareVersion } from '../../../utils/get-older-software-version.js';
import { useMemo } from 'react';

function isSmallerThanThreshold(version, threshold) {
  return version !== threshold && getOlderSoftwareVersion(threshold, version) === version;
}
function useUnitSoftware(units) {
  if (units === void 0) {
    units = [];
  }
  const groupedUnits = useMemo(() => {
    const unknownUnits = [];
    const compatibleUnits = [];
    const nonRecommendedUnits = [];
    const incompatibleUnits = [];
    units.forEach(unit => {
      const {
        software = ''
      } = unit || {};
      const softwarePrefix = getSoftwareVersionPrefix(software);
      const versionRequirements = softwareRequirements[softwarePrefix];
      if (!software) {
        unknownUnits.push(unit);
        return;
      }
      if (!versionRequirements || isSmallerThanThreshold(software, versionRequirements.min)) {
        incompatibleUnits.push(unit);
        return;
      }
      if (isSmallerThanThreshold(software, versionRequirements.recommended)) {
        nonRecommendedUnits.push(unit);
        return;
      }
      compatibleUnits.push(unit);
    });
    return {
      compatibleUnits,
      incompatibleUnits,
      nonRecommendedUnits,
      unknownUnits,
      softwareRequirements
    };
  }, [units]);
  return groupedUnits;
}

export { useUnitSoftware };
