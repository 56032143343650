import { isXmlError } from '@danfoss/etui-sm-xml';
import '../../../../../constants/software-versions.js';
import { UpdateSoftwareUnitStatus } from '../../../../../constants/software-update-unit-status.js';
import { delay } from '../utils/delay.js';
import { fetchReadUnits } from './fetch-read-units.js';

async function fetchUpdateSoftwareUnitStatus(url, user, sm800Delay) {
  try {
    const fetchDelay = sm800Delay ? sm800Delay + 5000 : 5000;
    await delay(fetchDelay);
    await fetchReadUnits(url, user);
    return UpdateSoftwareUnitStatus.UP;
  } catch (error) {
    if (isXmlError(error)) {
      throw error;
    }
    return UpdateSoftwareUnitStatus.DOWN;
  }
}

export { fetchUpdateSoftwareUnitStatus };
