import { Button, Toggle, icons } from '@danfoss/etui-core';
import { AppHeaderNavItemDropdown } from 'components/AppHeaderNavItemDropdown';
import React from 'react';
import {
  AllFeatureFlags,
  useFeatureHub,
} from 'hooks/featurehub/FeatureHubContext';
import { useLocalStorage } from 'hooks';
import * as S from './styles';

function ExperimentalSwitch() {
  const [localOverrides, setLocalOverrides] = useLocalStorage<
    Partial<Record<AllFeatureFlags, boolean>>
  >('featureFlagOverrides', {});
  const originalOverrides = React.useRef(localOverrides);
  const overridesChanged =
    JSON.stringify(originalOverrides.current) !==
    JSON.stringify(localOverrides);

  const { client } = useFeatureHub();
  // @ts-expect-error - .features is missing in the FeatureHubRepository type
  const features = client.repository().features as Map<
    string,
    ReturnType<ReturnType<typeof client.repository>['getFeatureState']>
  >;
  const experimentalFeatures = Array.from(features).filter(([key]) =>
    key.toLowerCase().includes('experimental'),
  );

  const handleSave = (key: string, isChecked: Boolean) => {
    setLocalOverrides({ ...localOverrides, [key]: isChecked });
  };

  return (
    <>
      <AppHeaderNavItemDropdown
        title={'feature switcher'}
        key="feature-switcher"
        iconProps={{ glyph: icons.CARWASH }}
        iconOnly={true}
      >
        <div style={{ maxHeight: '400px' }}>
          {experimentalFeatures.length === 0 && (
            <p style={{ padding: '10px' }}>
              Hey, forget about it, there ain't no experimental features here,
              capisce? 🤌
            </p>
          )}
          {experimentalFeatures.map(([key, value]) => (
            <AppHeaderNavItemDropdown.Item
              key={key}
              style={{
                width: '400px',
                display: 'flex',
                alignItems: 'center',
                padding: '30px',
              }}
            >
              <S.FeatureSwitchWrapper>
                <Toggle
                  name={key}
                  isChecked={value.isEnabled() || localOverrides[key]}
                  onChange={(_, isChecked) => handleSave(key, isChecked)}
                  renderLabels={() => key}
                />
              </S.FeatureSwitchWrapper>
            </AppHeaderNavItemDropdown.Item>
          ))}
        </div>
        {overridesChanged && (
          <AppHeaderNavItemDropdown.Item
            style={{
              width: '400px',
              display: 'flex',
              alignItems: 'center',
              padding: '30px',
              justifyContent: 'space-between',
            }}
          >
            Reload the page to apply changes
            <Button
              variant="secondary"
              testId="toggle-reload-button"
              small={true}
              onClick={() => window.location.reload()}
            >
              Reload
            </Button>
          </AppHeaderNavItemDropdown.Item>
        )}
      </AppHeaderNavItemDropdown>
    </>
  );
}

export default ExperimentalSwitch;
