import React from 'react';
import {
  SearchInput,
  SelectInput,
  SelectInputOption,
  SpinnerSize,
  Table,
} from '@danfoss/etui-core';
import { Div, Span } from '@danfoss/etui-system-elements';
import { useTranslation } from 'react-i18next';
import { ContentSpinner, EmptyState } from '@danfoss/etui-sm';
import { ConfigurationListItem } from '@danfoss/etui-sm-xml';
import { useTheme } from '@danfoss/etui-system';
import {
  ParametersTable,
  ParametersTableContent,
  ParamTableData,
} from '../../types/RefrigCopy.types';
import { useRefrigCopy } from '../../context';
import { generateNumberArray } from '../../../RefrigLayoutModal/utils';

export interface ParametersFlowTableProps {
  handleOnSelect: (record: ConfigurationListItem) => void;
}

export const ParametersFlowTable = ({
  handleOnSelect,
}: ParametersFlowTableProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    paramTableData,
    multipage,
    searchMode,
    isAllPageDataFetched,
    page,
    setParamTableData,
    setPage,
    setPageValues,
    onSetSearchValue,
    setSelectAll,
    selectAll,
    isPageDataFetched,
  } = useRefrigCopy();

  const tableColumns = [
    {
      title: t('t76'),
      dataIndex: 'name',
      key: 'name',
    },
  ];
  const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);
  const [paramTable, setParamTable] = React.useState<ParametersTableContent[]>(
    [],
  );
  const [parametersTablePage, setParametersTablePage] =
    React.useState<SelectInputOption[]>();
  const [parametersTableSelectedPage, setParametersTableSelectedPage] =
    React.useState<SelectInputOption>({
      value: '1',
      label: '1',
    });
  React.useEffect(() => {
    if (paramTableData?.paramTableContent?.length) {
      getTableData(paramTableData);
      if (!searchMode) {
        getParamPages();
      }
    }
  }, [paramTableData]);

  const getParamPages = () => {
    const pageValues: number[] = generateNumberArray(1, +multipage);
    const parametersPageOptions: SelectInputOption[] = [];
    pageValues.forEach(page => {
      parametersPageOptions.push({
        value: page.toString(),
        label: page.toString(),
      });
    });
    setPageValues(pageValues);
    setParametersTablePage(parametersPageOptions);
  };

  const handleOnPageChange = (options: SelectInputOption) => {
    const page: number = +options.label - 1;
    setParametersTableSelectedPage(options);
    setParamTableData(null);
    setPage(page.toString());
  };

  const getTableData = (parametersTableData: ParamTableData) => {
    const paramTable: ParametersTable[] = [];
    const rowKeys: number[] = [];
    if (+page === 0) {
      const selectData = parametersTableData?.paramTableContent
        .slice(0, 3)
        .filter(data => data.name.length > 1)
        .map(data => data.li);
      if (!selectAll?.length) {
        setSelectAll(selectData);
      }
      const tableData = searchMode
        ? parametersTableData?.paramTableContent.filter(
            item => item.toggleVal !== undefined,
          )
        : parametersTableData.paramTableContent.slice(4);

      tableData.forEach((content, index) => {
        if (content.toggleVal === '1') {
          rowKeys.push(index);
        }
        paramTable.push({
          name: content.name,
          toggleVal: content.toggleVal,
          key: index,
          li: content.li,
        });
      });
      setParamTable(paramTable);
      setSelectedRowKeys(rowKeys);
    } else {
      parametersTableData?.paramTableContent.forEach((content, index) => {
        if (content.toggleVal === '1') {
          rowKeys.push(index);
        }
        paramTable.push({
          name: content.name,
          toggleVal: content.toggleVal,
          key: index,
          li: content.li,
        });
      });
      setParamTable(paramTable);
      setSelectedRowKeys(rowKeys);
    }
  };

  const handleRowSelectionChange = async (selectedKeys: string[]) => {
    setSelectedRowKeys(selectedKeys);
  };

  const rowSelection = React.useMemo(() => {
    return {
      selectedRowKeys,
      onChange: handleRowSelectionChange,
      onSelect: record => {
        handleOnSelect(record.li);
      },
      onSelectAll: (selected, selectedRows) => {
        selected && selectedRows?.length
          ? handleOnSelect(selectAll[0])
          : handleOnSelect(selectAll[1]);
      },
      columnWidth: '1%',
    };
  }, [selectedRowKeys.length, paramTable]);

  return (
    <>
      <Div
        testId="parameters-flow-table-search-page-div"
        style={{
          width: '60%',
          display: 'inline-block',
        }}
      >
        <Span
          style={{
            width: '30%',
            display: 'inline-block',
          }}
        >
          <SearchInput
            placeholder="Search for points"
            testId="configOld-parameterPoint-searchInput"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onSetSearchValue(e.target.value)
            }
            styles={{
              input: {
                borderColor: 'grey',
                background: 'white',
              },
            }}
          />
        </Span>
        {!searchMode ? (
          <Span
            style={{
              width: '30%',
              marginLeft: '10px',
              display: 'inline-block',
            }}
          >
            <SelectInput
              name=""
              options={parametersTablePage}
              value={parametersTableSelectedPage}
              onChange={handleOnPageChange}
              size="small"
            />
          </Span>
        ) : null}
      </Div>

      {!isPageDataFetched || (searchMode && !isAllPageDataFetched) ? (
        <Div
          testId="paramters-flow-table-spinner-div"
          style={{ marginTop: '200px' }}
        >
          <ContentSpinner size={SpinnerSize.small} transparent={true} />
        </Div>
      ) : !paramTable.length ? (
        <Div
          testId="paramters-flow-table-empty-div"
          style={{ marginTop: '200px' }}
        >
          <EmptyState title="No data available" />
        </Div>
      ) : (
        <Div
          testId="paramters-flow-table-content-div"
          style={{ marginTop: '20px' }}
        >
          <Table
            styles={{
              td: { p: `${theme.spacing.sm}px`, height: 10 },
              root: { overflow: 'visible' },
            }}
            columns={tableColumns}
            dataSource={paramTable}
            rowSelection={rowSelection}
            pagination={false}
          />
        </Div>
      )}
    </>
  );
};
