import { trimUrlEnding, getUnitMetadata } from '@danfoss/etui-sm-xml';
import '@danfoss/etui-core';
import '../constants/session-storage-constants.js';
import '../constants/software-versions.js';
import '../actions/authorize-units.js';
import '../_rollupPluginBabelHelpers-e1ae8e90.js';
import { fetchInitialUnit } from '../actions/fetch-initial-unit.js';
import '../actions/fetch-metadata-files.js';
import '../actions/fetch-other-units.js';
import '../utils/get-measurements-by-unit.js';
import '../utils/get-software-version-prefix.js';
import '../utils/get-is-matched-software-version.js';
import '../utils/get-is-misc-device.js';
import '../utils/get-language-code-by-name.js';
import '../utils/get-user-from-storage.js';
import 'react';
import 'react-i18next';
import useSWR from 'swr';
import '../context/modal/modal-context.js';
import '../context/modal/use-modal.js';
import 'use-deep-compare-effect';
import '../context/app/utils/get-xml-backend-ip.js';
import '../context/app/utils/get-xml-backend-protocol.js';
import '../context/app/utils/get-xml-proxy-config.js';
import '../context/app/utils/set-xml-proxy-config.js';
import '../context/app/app-context.js';
import '../context/app/hooks/use-app.js';
import { useXmlResource } from '../context/app/hooks/use-xml-resource.js';
import '@danfoss/etui-sm-i18n';
import '../context/auth/utils/logout-clean-up.js';
import '../context/auth/utils/set-user-to-storage.js';
import '../context/auth/auth-context.js';
import { useAuth } from '../context/auth/hooks/use-auth.js';
import 'styled-components';
import '../context/unit/utils/duplicate-session-info.js';
import '../context/unit/unit-actions.js';
import '../context/unit/unit-context.js';
import 'lodash.isequal';
import 'react-device-detect';
import 'featurehub-javascript-client-sdk';

const MINIMUM_TIMEOUT_INTERVAL = 55000;
const oldInstallationVersionPropName = 'Installation-version';
const newInstallationVersionPropName = 'Installation_version';
function useKeepSessionAlive() {
  const {
    url
  } = useXmlResource();
  const {
    user
  } = useAuth();
  const trimmedUrl = trimUrlEnding(url);
  const metadata = getUnitMetadata(trimmedUrl) || {};
  const installationVersion = metadata[newInstallationVersionPropName] || metadata[oldInstallationVersionPropName];
  const shouldSendRequest = installationVersion && +installationVersion[0] > 3;
  useSWR(shouldSendRequest && [url, user], _ref => {
    let [url, user] = _ref;
    return fetchInitialUnit(url, user);
  }, {
    refreshInterval: MINIMUM_TIMEOUT_INTERVAL
  });
}

export { useKeepSessionAlive };
