import { isXmlError } from '@danfoss/etui-sm-xml';
import '../../../../../constants/software-update-type.js';
import '../../../../../constants/session-storage-constants.js';
import '../../../../../constants/software-versions.js';
import { UpdateSoftwareUnitStatus } from '../../../../../constants/software-update-unit-status.js';
import { SESSION_EXPIRY_CODE } from '../../../../../constants/index.js';
import { delay } from '../utils/delay.js';
import { fetchReadUnits } from './fetch-read-units.js';

async function fetchLocalGraphicsUpdateSoftwareUnitStatus(url, user, sm800Delay, isBCMode) {
  try {
    const fetchDelay = sm800Delay ? sm800Delay + 5000 : 5000;
    if (isBCMode) {
      await delay(fetchDelay);
    }
    await fetchReadUnits(url, user);
    return UpdateSoftwareUnitStatus.UP;
  } catch (error) {
    if (!isBCMode) {
      const e = {
        code: SESSION_EXPIRY_CODE
      };
      throw e;
    }
    if (isXmlError(error)) {
      throw error;
    }
    return UpdateSoftwareUnitStatus.DOWN;
  }
}

export { fetchLocalGraphicsUpdateSoftwareUnitStatus };
