import * as React from 'react';
import { useHistory } from 'react-router-dom';
import useSWR from 'swr';
import { ConfigurationTabItem, Unit } from '@danfoss/etui-sm-xml';
import {
  Skeleton,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemToggle,
  AccordionItemPanel,
} from '@danfoss/etui-sm/components';
import { Button, icons } from '@danfoss/etui-core';
import { useTheme } from '@danfoss/etui-system';
import { Div } from '@danfoss/etui-system-elements';
import { darken } from '@danfoss/etui-colors';
import { useXmlResource } from '@danfoss/etui-sm/context/app';
import { useAuth } from '@danfoss/etui-sm/context/auth';
import useDeepCompareEffect from 'use-deep-compare-effect';
import {
  ConfigurationTabByUnitResponse,
  fetchConfigurationTabByUnit,
} from '../../actions';
import { useConfiguration } from '../../context';
import { ConfigurationMenuItem } from '../ConfigurationMenuItem';

type ConfigurationMenuGroupProps = {
  item: ConfigurationTabItem;
  unit: Unit;
};

const ConfigurationMenuGroup = React.memo(
  ({ item, unit }: ConfigurationMenuGroupProps) => {
    const theme = useTheme();
    const { url: xmlBackendURL } = useXmlResource();
    const { user } = useAuth();
    const { location } = useHistory();
    const { menuItemsById, onSetMenuItems, cachedDevices } = useConfiguration();

    const menuId = item.id;

    const existingMenuItems = menuItemsById[menuId];

    const isInfoTab = item.id === '20105-2';

    const { data, error } = useSWR<ConfigurationTabByUnitResponse>(
      () =>
        !isInfoTab && !existingMenuItems
          ? [xmlBackendURL, unit, item.id, user, cachedDevices]
          : null,
      fetchConfigurationTabByUnit,
      {
        revalidateOnFocus: false,
        shouldRetryOnError: true,
      },
    );

    const isBusy = !existingMenuItems && !isInfoTab && !data && !error;

    const loadedTabs = data ? data.tabs : [];

    const tabs = existingMenuItems || loadedTabs;

    const hasNoChildMenus = tabs && tabs.length === 0;

    const isActive = location.pathname.includes(item.id);

    useDeepCompareEffect(() => {
      !existingMenuItems && onSetMenuItems(menuId, loadedTabs);
    }, [menuId, loadedTabs, location.pathname, existingMenuItems]);

    return (
      <AccordionItem uuid={item.id}>
        <AccordionItemHeading
          testId="configuration-menuGroup-accordion"
          display="flex"
          height="50px"
          alignItems="center"
          borderBottom={
            !isActive || hasNoChildMenus
              ? `1px solid ${theme.palette.divider}`
              : 'none'
          }
          bg={
            isActive && !hasNoChildMenus
              ? theme.palette.common.bg
              : theme.palette.common.white
          }
          boxSizing="content-box"
        >
          {({ expanded, disabled }) => (
            <AccordionItemToggle
              display="flex"
              alignItems="center"
              width="100%"
              height="50px"
              borderLeft={
                isActive && hasNoChildMenus
                  ? `3px solid ${theme.palette.primary.main}`
                  : isActive
                  ? `3px solid ${darken(theme.palette.common.bg, 0.25)}`
                  : '3px solid transparent'
              }
              color={theme.palette.text.primary}
              p={`${theme.spacing.xs}px ${theme.spacing.xs}px ${theme.spacing.xs}px ${theme.spacing.xs}px`}
              outline={0}
              {...theme.typography.button}
            >
              {isBusy ? (
                <Skeleton
                  height={`${40 - 16}px`}
                  width="100%"
                  m={`${theme.spacing.xs}px 0`}
                  opacity={0.5}
                />
              ) : (
                <Div flexGrow="1">{item.label}</Div>
              )}

              {!isBusy && !hasNoChildMenus ? (
                <Button
                  variant="tertiary"
                  iconProps={{
                    glyph: icons.CHEVRON_DOWN,
                    size: 16,
                    rotate: expanded ? 0 : -90,
                  }}
                  testId="configuration-menuGroup-button"
                  small={true}
                  styles={{
                    root: { p: `${theme.spacing.xs}px` },
                  }}
                  disabled={disabled}
                />
              ) : null}
            </AccordionItemToggle>
          )}
        </AccordionItemHeading>
        {!hasNoChildMenus ? (
          <AccordionItemPanel
            testId="configuration-menugroup-panel-accordion"
            key={`accordion-key-${menuId}`}
            borderBottom={`1px solid ${theme.palette.divider}`}
            bg={theme.palette.common.bg}
            minHeight={tabs && `calc(${tabs.length * 48}px)`}
          >
            {({ expanded }) =>
              expanded && tabs
                ? tabs.map(tab => (
                    <ConfigurationMenuItem
                      key={tab.id}
                      menuGroupId={item.id}
                      item={tab}
                      unit={unit}
                    />
                  ))
                : null
            }
          </AccordionItemPanel>
        ) : null}
      </AccordionItem>
    );
  },
);

export { ConfigurationMenuGroup };
