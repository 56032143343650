import React from 'react';
import {
  SearchInput,
  SelectInput,
  SelectInputOption,
  SpinnerSize,
  Table,
} from '@danfoss/etui-core';
import { Div, Span } from '@danfoss/etui-system-elements';
import { useTranslation } from 'react-i18next';
import { ContentSpinner, EmptyState } from '@danfoss/etui-sm';
import { ConfigurationListItem } from '@danfoss/etui-sm-xml';
import { useTheme } from '@danfoss/etui-system';
import {
  GraphTable,
  GraphTableContent,
  GraphTableData,
} from '../../types/RefrigCopy.types';
import { useRefrigCopy } from '../../context';
import { generateNumberArray } from '../../../RefrigLayoutModal/utils';

export interface GraphFlowTableProps {
  handleOnSelect: (record: ConfigurationListItem) => void;
}

export const GraphFlowTable = ({ handleOnSelect }: GraphFlowTableProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    graphTableData,
    multipage,
    searchMode,
    isAllPageDataFetched,
    setPage,
    setPageValues,
    onSetSearchValue,
    setSelectAll,
    selectAll,
    isPageDataFetched,
  } = useRefrigCopy();

  const tableColumns = [
    {
      title: t('t76'),
      dataIndex: 'name',
      width: '20%',
      key: 'name',
    },
    {
      title: t('t540'),
      dataIndex: 'interval',
      key: 'interval',
    },
  ];
  const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);
  const [graphTable, setGraphTable] = React.useState<GraphTableContent[]>([]);
  const [graphTablePage, setGraphTablePage] =
    React.useState<SelectInputOption[]>();
  const [graphTableSelectedPage, setGraphTableSelectedPage] =
    React.useState<SelectInputOption>({
      value: '1',
      label: '1',
    });
  React.useEffect(() => {
    if (graphTableData?.graphTableContent.length) {
      getTableData(graphTableData);
      if (!searchMode) {
        getGraphPages();
      }
    }
  }, [graphTableData]);

  const getGraphPages = () => {
    const pageValues: number[] = generateNumberArray(1, +multipage);
    const graphPageOptions: SelectInputOption[] = [];
    pageValues.forEach(page => {
      graphPageOptions.push({
        value: page.toString(),
        label: page.toString(),
      });
    });
    setPageValues(pageValues);
    setGraphTablePage(graphPageOptions);
  };

  const handleOnPageChange = (options: SelectInputOption) => {
    const page: number = +options.label - 1;
    setGraphTableSelectedPage(options);
    setPage(page.toString());
  };

  const getTableData = (graphTableData: GraphTableData) => {
    const graphTable: GraphTable[] = [];
    const rowKeys: number[] = [];
    const selectData = graphTableData?.graphTableContent
      .slice(0, 4)
      .filter(data => data.name.length > 1)
      .map(data => data.li);
    setSelectAll(selectData);

    const tableData = searchMode
      ? graphTableData?.graphTableContent.filter(
          item => item.toggleVal !== undefined,
        )
      : graphTableData?.graphTableContent.slice(5);

    tableData.forEach((content, index) => {
      if (content.toggleVal === '1') {
        rowKeys.push(index);
      }
      graphTable.push({
        name: content.name,
        interval: content.interval,
        toggleVal: content.toggleVal,
        key: index,
        li: content.li,
      });
    });
    setGraphTable(graphTable);
    setSelectedRowKeys(rowKeys);
  };

  const handleRowSelectionChange = async (selectedKeys: string[]) => {
    setSelectedRowKeys(selectedKeys);
  };

  const rowSelection = React.useMemo(() => {
    return {
      selectedRowKeys,
      onChange: handleRowSelectionChange,
      onSelect: record => {
        handleOnSelect(record.li);
      },
      onSelectAll: (selected, selectedRows) => {
        selected && selectedRows?.length
          ? handleOnSelect(selectAll[0])
          : handleOnSelect(selectAll[1]);
      },
      columnWidth: '1%',
    };
  }, [selectedRowKeys.length, graphTable]);

  return (
    <>
      <Div
        testId="graph-flow-table-search-page-div"
        style={{
          marginTop: '10px',
          width: '60%',
          display: 'flex',
        }}
      >
        <Span
          style={{
            width: '30%',
            display: 'inline-block',
          }}
        >
          <SearchInput
            placeholder={t('t3401')}
            testId="configuration-graphPoints-searchInput"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onSetSearchValue(e.target.value)
            }
            styles={{
              input: {
                borderColor: 'grey',
                background: 'white',
              },
            }}
          />
        </Span>
        {!searchMode ? (
          <Span
            style={{
              width: '30%',
              marginLeft: '10px',
              display: 'inline-block',
            }}
          >
            <SelectInput
              name=""
              options={graphTablePage}
              value={graphTableSelectedPage}
              onChange={handleOnPageChange}
              size="small"
            />
          </Span>
        ) : null}
      </Div>

      {!isPageDataFetched || (searchMode && !isAllPageDataFetched) ? (
        <Div
          testId="graph-flow-table-spinner-div"
          style={{ marginTop: '200px' }}
        >
          <ContentSpinner size={SpinnerSize.small} transparent={true} />
        </Div>
      ) : !graphTable.length ? (
        <Div testId="graph-flow-table-empty-div" style={{ marginTop: '200px' }}>
          <EmptyState title="No data available" />
        </Div>
      ) : (
        <Div
          testId="graph-flow-table-content-div"
          style={{ marginTop: '20px' }}
        >
          <Table
            styles={{
              td: { p: `${theme.spacing.sm}px`, height: 10 },
              root: { overflow: 'visible' },
            }}
            columns={tableColumns}
            dataSource={graphTable}
            rowSelection={rowSelection}
            pagination={false}
          />
        </Div>
      )}
    </>
  );
};
