import * as React from 'react';
import {
  Button,
  Modal,
  CommandBar,
  CommandBarItemProps,
  Tabs,
  icons,
  Row,
  Col,
} from '@danfoss/etui-core';
import { Div } from '@danfoss/etui-system-elements';
import { useTheme } from '@danfoss/etui-system';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import useDeepCompareEffect from 'use-deep-compare-effect';
import {
  CONFIGURATION_REFRESH_INTERVAL,
  ConfigurationListItem as ConfigurationListItemProp,
  ConfigurationTabItem,
  Device,
  getUnitUrl,
  Unit,
} from '@danfoss/etui-sm-xml';
import {
  ContentSpinner,
  useAlarm,
  useAuth,
  useXmlResource,
} from '@danfoss/etui-sm';
import { darken } from '@danfoss/etui-colors';
import { useHistory } from 'react-router-dom';
import { useConfigurationAuth, useConfigDevices } from '../../hooks';
import {
  ConfigurationTabByUnitResponse,
  fetchConfigurationTabByUnit,
  fetchConfigurationTabsByUnit,
  writeConfigurationListItem,
} from '../../actions';
import {
  ConfigurationItemContentMenuDeviceGroupSelect,
  ConfigurationItemContentMenuDeviceSelect,
  ConfigurationItemContentMenuPageSelect,
} from '..';
import { withErrorHandled } from '../../utils/with-error-handled';
import { useConfiguration, useConfigurationModals } from '../../context';
import { TABLE_ADDRESS } from '../../utils';
import { ConfigurationListItem } from './ConfigurationListItem';
import {
  getHtmlId,
  getAuditNames,
  updateListInSWRCache,
  isDeviceNetworkNodePointTypes,
  isNetworkNodePointTypesTable,
} from './utils';
import { getAddresses } from './utils/address-utils';
import * as S from './styles';

function ConfigurationListItemEditInModal({
  item,
  unit,
}: {
  unit: Unit;
  item: ConfigurationListItemProp;
}) {
  const theme = useTheme();
  const { t } = useTranslation();
  const {
    location: { pathname },
  } = useHistory();
  const { url } = useXmlResource();
  const { user } = useAuth();
  const { setShouldRequest } = useAlarm();

  const { openModals, toggleModals, closeAllModals } = useConfigurationModals();
  const { cachedDevices, configurationDataItem, activeMenuItem } =
    useConfiguration();

  const itemId = item.name + item.li;
  const deleteItemId = `${itemId}-delete`;

  const [processedList, setProcessedList] = React.useState([]);
  const [device, setDevice] = React.useState<Device>(null);
  const [deviceGroup, setDeviceGroup] = React.useState('0');
  const [deviceSubgroup, setDeviceSubgroup] = React.useState('0');
  const [page, setPage] = React.useState('0');
  const [activeTab, setActiveTab] = React.useState<ConfigurationTabItem>(
    {} as ConfigurationTabItem,
  );
  const [isOpen, setIsOpen] = React.useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = React.useState(false);

  const [localUseParent, setLocalUseParent] = React.useState<'0' | '1'>('1');
  const tableName = item.ltn.length === 0 ? item.ltn : '';
  const { combo, stype } = activeTab;
  const isCalculationPage = pathname.includes(TABLE_ADDRESS.MISC_CALCULATIONS);

  React.useEffect(() => {
    mutateDevices(null);
  }, [configurationDataItem.length]);

  const {
    devices,
    isLoading: areDevicesBeingLoaded,
    mutate: mutateDevices,
  } = useConfigDevices({
    unit,
    combo,
    stype,
  });

  React.useEffect(() => {
    if (!devices.length) return;
    const itemCombo =
      isNetworkNodePointTypesTable(activeMenuItem) &&
      isDeviceNetworkNodePointTypes(devices)
        ? +item.combo - 1
        : +item.combo;
    const preSelectedDevice = devices.find(d => +d.comboindex === itemCombo);
    setDevice(preSelectedDevice);
  }, [devices]);

  const { data: tabs = [], isLoading: areTabsBeingLoaded } = useSWR(
    () => (isOpen ? [url, unit, tableName, item.lta, user] : null),
    fetchConfigurationTabsByUnit,
    {
      revalidateOnFocus: false,
      shouldRetryOnError: true,
    },
  );

  const { getItemIsAuthorized } = useConfigurationAuth(activeTab, user);

  useDeepCompareEffect(() => {
    if (tabs.length && !activeTab.id) {
      setActiveTab(tabs[0]);
    }
  }, [tabs]);

  const handleOpen = () => {
    toggleModals(itemId);
    setShouldRequest(false);
    setIsOpen(true);
  };

  const handleClose = () => {
    !isCalculationPage && setActiveTab({} as ConfigurationTabItem);
    setDevice(null);
    toggleModals(itemId);
    setIsOpen(false);
    setShouldRequest(true);
    closeAllModals();
  };

  const {
    data = {} as ConfigurationTabByUnitResponse,
    isLoading,
    mutate,
  } = useSWR(
    () =>
      (isOpen || isConfirmOpen) && !areTabsBeingLoaded && !areDevicesBeingLoaded
        ? [
            url + device?.name + activeTab.id + page, // serves as key for useSWR
            url, // sourceUrl: string,
            unit, // unit: Unit,
            `${item.lta}-${activeTab.index}`, // tabId: string,
            user, // user
            cachedDevices,
            true, // skipSessionUpdate
            localUseParent, // useparent

            '0', // isconfigure
            device?.bpidx ? +device.bpidx - 1 : '0', // bpidx
            device?.stype || '', // stype
            device?.nodetype || '0', // nodetype
            device?.node || '0', // node

            deviceGroup || item.group, // item.group, // group
            deviceSubgroup || item.subgroup, // subgroup
            page, // page
            activeTab.combo, // combo
            activeTab.configuretype || '0', // configuretype

            device?.arg1 || item.combo || item.iVal || '0', // arg1
            device?.arg2 || '0', // arg2
            device?.arg3 || '0', // arg3
            null,
            activeTab.configuretype || '0', // old_cfgtype
            item.ltn, // listtabname

            item.pnum, // pnum
            item.pnum, // parentpnum
            item.li, // listindex
            item.li, // parentlistindex
            item.bnum, // bnum

            item.bnum, // parentbnum
            item.group, // parentgroup
            item.cpi, // calptindex
            item.group, // listgoup
            item.combo, // listcombo
          ]
        : null,
    // @ts-ignore
    ([cacheKey, ...args]) => fetchConfigurationTabByUnit(args),
    {
      revalidateOnFocus: false,
      shouldRetryOnError: true,
      refreshInterval: isOpen ? CONFIGURATION_REFRESH_INTERVAL.DEFAULT : 0,
    },
  );

  const resetConfigSwrCache = () => {
    mutate({} as ConfigurationTabByUnitResponse);
  };

  const { list = [] } = data;

  useDeepCompareEffect(() => {
    setProcessedList(list);
  }, [list]);

  const handleSave = React.useCallback(
    async (listItem, { value, ival, fval = null, valueProperty, tmpValue }) => {
      const res = await withErrorHandled(t, theme, writeConfigurationListItem)(
        getUnitUrl(url, unit),
        user,
        getAuditNames(list, +listItem.li, ''),
        `-${item.lta}-${activeTab.index}`,
        device,
        deviceGroup,
        activeTab.configuretype || '0', // configuretype
        listItem,
        value,
        ival,
        fval,
        item,
        localUseParent,
      );

      // display updated value in the list between write_list and read_list commands
      updateListInSWRCache(data, listItem.li, valueProperty, tmpValue, mutate);

      return res;
    },
    [isOpen, item, list, unit, url, user],
  );

  const handleOnSetActiveTab = (tabIndex: string | number) => {
    setActiveTab(tabs[tabIndex]);
  };

  const handleModalDeviceChange = (newDevice: Device) => {
    setDevice(newDevice);
    handleModalDeviceGroupChange('0');
    setLocalUseParent('0');
  };

  const handleModalDeviceGroupChange = (newGroup: string) => {
    setDeviceGroup(newGroup);
    setDeviceSubgroup('0');
  };

  const handleModalDeviceSubgroupChange = (newSubgroup: string) => {
    setDeviceSubgroup(newSubgroup);
    setPage('0');
  };

  const handleModalPageChange = (newPage: string) => {
    setPage(newPage);
  };

  // TODO extract device based dumb component
  function getCommandBarItems() {
    if (!activeTab.id || areDevicesBeingLoaded || (devices.length && !device)) {
      return [];
    }

    const deviceMenuItems: CommandBarItemProps[] = [];

    if (devices.length) {
      deviceMenuItems.push({
        key: 'deviceSelect',
        onRender: ({ key }) => (
          <ConfigurationItemContentMenuDeviceSelect
            key={key}
            combo={combo}
            onDeviceChange={handleModalDeviceChange}
            selectedDevice={device}
            devices={devices}
          />
        ),
      });

      if (data.groupnames?.length) {
        deviceMenuItems.push({
          key: 'deviceGroupSelect',
          onRender: ({ key }) => (
            <ConfigurationItemContentMenuDeviceGroupSelect
              name={key}
              selectedValue={deviceGroup}
              menugroups={data.groupnames}
              onChange={handleModalDeviceGroupChange}
            />
          ),
        });
      }
    }

    if (data.subgroupnames?.length > 1) {
      deviceMenuItems.push({
        key: 'deviceSubgroupSelect',
        onRender: ({ key }) => (
          <ConfigurationItemContentMenuDeviceGroupSelect
            name={key}
            selectedValue={deviceSubgroup}
            menugroups={data.subgroupnames}
            onChange={handleModalDeviceSubgroupChange}
          />
        ),
      });
    }

    if (Number(data.multipage) > 0) {
      deviceMenuItems.push({
        key: 'pageSelect',
        onRender: ({ key }) => (
          <ConfigurationItemContentMenuPageSelect
            key={key}
            multipage={data.multipage}
            onPageChange={handleModalPageChange}
            selectedPage={page}
          />
        ),
      });
    }

    return deviceMenuItems;
  }

  const addresses = React.useMemo(() => getAddresses(list), [list]);

  const toggleConfirmModal = () => {
    setIsConfirmOpen(isOpen => !isOpen);
    toggleModals(deleteItemId);
  };

  const commandBarItems = React.useMemo(
    () => getCommandBarItems(),
    [data, activeTab.id, areDevicesBeingLoaded, devices, device],
  );

  return (
    <>
      <Modal
        key={itemId}
        onClose={handleClose}
        isOpen={isOpen}
        header={<Modal.Header title={device?.name || item.name} />}
        style={{
          content: {
            maxWidth: '90%',
            width: '99vw',
            maxHeight: '90%',
            height: '99vh',
            background: darken(theme.palette.common.bg, 0.04),
          },
          overlay: {},
        }}
      >
        <Div height="100%" display="flex" flexDirection="column">
          {tabs.length > 1 && (
            <Tabs onChange={handleOnSetActiveTab} value={+activeTab.index || 0}>
              {tabs.map((tab, i) => (
                <Tabs.Tab
                  key={tab.id}
                  tab={tab.label}
                  value={i}
                  styles={{ root: { borderBottom: 'none' } }}
                />
              ))}
            </Tabs>
          )}
          {commandBarItems.length ? (
            <CommandBar
              items={commandBarItems}
              styles={{
                root: { m: null },
                leftSide: { width: '100%' },
              }}
            />
          ) : null}
          {areDevicesBeingLoaded || !processedList.length ? (
            <Div testId="config-modal-spinner">
              <ContentSpinner size={1} transparent={true} />
            </Div>
          ) : (
            <Div flex="1" overflowY="auto">
              {processedList.map((l, i) => (
                <ConfigurationListItem
                  key={l.li + combo}
                  item={l}
                  unit={unit}
                  isEditable={true}
                  isAuthorized={getItemIsAuthorized(l)}
                  htmlId={getHtmlId(l, i)}
                  onSave={handleSave}
                  addresses={addresses}
                  resetConfigSwrCache={resetConfigSwrCache}
                />
              ))}
            </Div>
          )}
        </Div>
      </Modal>

      <ConfirmationModal
        isOpen={isConfirmOpen}
        toggleModal={toggleConfirmModal}
        onConfirm={handleSave}
        list={list}
        isLoading={isLoading}
      />

      <Div display="flex" justifyContent="space-between" alignItems="center">
        <S.PreWrapper>
          <pre>{item.value}</pre>
        </S.PreWrapper>
        <Div display="flex" alignItems="center">
          <Button
            variant="secondary"
            testId="configuration-editModal-open-button"
            onClick={handleOpen}
            height="38px"
          >
            {t('t3000')}
          </Button>
          {isCalculationPage && !openModals.length ? (
            <Button
              iconOnly={true}
              iconProps={{
                glyph: icons.TRASH,
                size: 24,
              }}
              testId="configuration-editModal-delete-button"
              onClick={toggleConfirmModal}
              small={true}
            />
          ) : null}
        </Div>
      </Div>
    </>
  );
}

function ConfirmationModal({
  isOpen,
  list,
  onConfirm,
  toggleModal,
  isLoading,
}) {
  const handleClick = () => {
    onConfirm(list[0], {});
    if (!isLoading) {
      toggleModal();
    }
  };
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isOpen}
      onClose={toggleModal}
      header={<Modal.Header title={t('t214')} />}
      actionButtons={[
        {
          children: t('t45'),
          variant: 'secondary',
          onClick: toggleModal,
        },
        {
          children: t('t44'),
          variant: 'primary',
          onClick: handleClick,
          disabled: !list?.length,
        },
      ]}
    >
      <Row>
        <Col xs={12}>
          <Div>{t('t186')}</Div>
        </Col>
      </Row>
    </Modal>
  );
}

export { ConfigurationListItemEditInModal };
