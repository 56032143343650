import {
  ConfigurationTabItem,
  XML_REFRIG_COPY_TAB_INDEX,
} from '@danfoss/etui-sm-xml';
import { TFunction } from 'i18next';
import { RefrigCopySteps } from '../modules/RefrigCopyModal/types/RefrigCopy.types';

export const getRefrigCopySteps = (
  tabs: ConfigurationTabItem[],
  t: TFunction,
): RefrigCopySteps[] => {
  tabs = tabs?.filter(
    tab => tab?.index !== XML_REFRIG_COPY_TAB_INDEX.INTRODUCTION,
  );
  return tabs?.map((step, index) => {
    return {
      title:
        step.index === XML_REFRIG_COPY_TAB_INDEX.SOURCE_CONTROLLER
          ? t('t3387')
          : step.index === XML_REFRIG_COPY_TAB_INDEX.SOURCE_SETTINGS
          ? t('t3388')
          : step.index === XML_REFRIG_COPY_TAB_INDEX.SOURCE_ALARMS
          ? t('t3389')
          : step.index === XML_REFRIG_COPY_TAB_INDEX.SOURCE_GRAPH
          ? t('t3390')
          : step.index === XML_REFRIG_COPY_TAB_INDEX.FILES
          ? t('t653')
          : step.index === XML_REFRIG_COPY_TAB_INDEX.PARAMETERS
          ? t('t941')
          : step.index === XML_REFRIG_COPY_TAB_INDEX.ALARMS
          ? t('t152')
          : step.index === XML_REFRIG_COPY_TAB_INDEX.GRAPH
          ? t('t3392')
          : step.index === XML_REFRIG_COPY_TAB_INDEX.DEVICES
          ? t('t3393')
          : step.index === XML_REFRIG_COPY_TAB_INDEX.COPY
          ? t('t486')
          : step.label,
      stepNumber: index + 1,
    };
  });
};
