import * as React from 'react';
import { Button } from '@danfoss/etui-core';
import { Div } from '@danfoss/etui-system-elements';
import { ConfigurationListItem } from '@danfoss/etui-sm-xml';
import { useModal } from '@danfoss/etui-sm';
import { ConfigurationListItemEditStagingPatternModal } from './ConfigurationListItemEditStagingPatternModal';

export interface ConfigurationListItemEditStagingPatternStepProps {
  item: ConfigurationListItem;
}

export const ConfigurationListItemEditStagingPatternStep = ({
  item,
}: ConfigurationListItemEditStagingPatternStepProps) => {
  const [showStagingPatternModal] = useModal(
    ConfigurationListItemEditStagingPatternModal,
    { item },
  );
  return (
    <Div>
      <Button
        styles={{ root: { width: '100%' } }}
        small={true}
        variant="secondary"
        testId="config-staging-pattern-button"
        onClick={showStagingPatternModal}
      >
        {item.value}
      </Button>
    </Div>
  );
};
