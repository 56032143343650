import React from 'react';
import { Div, Span } from '@danfoss/etui-system-elements';
import { Button, icons } from '@danfoss/etui-core';
import { useTranslation } from 'react-i18next';

export interface CircuitsFlowAddCircuitProps {
  handleOnAddCircuit: () => void;
}
export const CircuitsFlowAddCircuit = ({
  handleOnAddCircuit,
}: CircuitsFlowAddCircuitProps) => {
  const { t } = useTranslation();
  return (
    <Div
      testId="add-case"
      style={{
        width: '100%',
        display: 'inline-block',
      }}
    >
      <Div
        style={{
          display: 'inline-block',
        }}
        testId="add-case-button"
      >
        <Span>
          <Button
            iconOnly={true}
            iconProps={{ glyph: icons.ADD, isIconLabel: true }}
            testId="refrig-layout-addCircuit-button"
            onClick={handleOnAddCircuit}
          />
        </Span>
        <Span>{t('t3371')}</Span>
      </Div>
    </Div>
  );
};
