import * as React from 'react';
import { Div, P } from '@danfoss/etui-system-elements';
import { Button, Form, Modal, PasswordInput } from '@danfoss/etui-core';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@danfoss/etui-system';
import { useForm } from 'react-hook-form';

export const PasswordPrompt = ({
  isOpen,
  onClose,
  promptMessage,
  onResolve,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { handleSubmit } = useForm();
  const [password, setPassword] = React.useState('');

  const handleChange = e => setPassword(e.target.value);
  const handleOnSubmit = () => onResolve(password);

  const passwordProps = {
    id: 'address-book-password',
    value: password,
    autoFocus: true,
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      style={{
        content: {
          position: 'absolute',
          top: '20px',
          maxWidth: '350px',
          minWidth: '350px',
        },
      }}
    >
      <P>{t(promptMessage)}</P>
      <Form
        testId="address-book-password-form"
        onSubmit={handleSubmit(handleOnSubmit)}
        styles={{
          root: {
            mt: `${theme.spacing.sm}px`,
          },
        }}
      >
        <PasswordInput
          autoComplete="address-book-password"
          name="address-book-password"
          testId="address-book-passwordInput"
          label={`${t('t72')} ${t('t3513')}`}
          onChange={handleChange}
          {...passwordProps}
        />
        <Div display="flex" mt={`${theme.spacing.md}px`}>
          <Button
            id="cancelButton"
            variant="tertiary"
            block={true}
            tabIndex={0}
            testId="passwordPrompt-cancel-button"
            onClick={onClose}
          >
            {t('t45')}
          </Button>
          <Button
            id="saveButton"
            value="saveButton"
            type="submit"
            variant="primary"
            block={true}
            tabIndex={0}
            testId="passwordPrompt-save-button"
            ml={`${theme.spacing.xs}px`}
            disabled={!password.length}
          >
            {t('t266')}
          </Button>
        </Div>
      </Form>
    </Modal>
  );
};
