import * as React from 'react';
import { Unit } from '@danfoss/etui-sm-xml';
import { useKeepSessionAlive } from '@danfoss/etui-sm';
import { ConfigurationProvider } from '../../../Configuration/context';
import { RefrigCopyProvider } from './context';
import { RefrigCopyInnerModal } from './RefrigCopyInnerModal';

export interface RefrigarationCopyModalProps {
  isOpen: boolean;
  onClose: () => void;
  unit: Unit;
  isHvac?: boolean;
}
function RefrigCopyModal({
  isOpen,
  onClose,
  unit,
  isHvac,
}: RefrigarationCopyModalProps) {
  useKeepSessionAlive();
  return (
    <ConfigurationProvider>
      <RefrigCopyProvider>
        <RefrigCopyInnerModal
          isOpen={isOpen}
          onClose={onClose}
          unit={unit}
          isHvac={isHvac}
        />
      </RefrigCopyProvider>
    </ConfigurationProvider>
  );
}

export { RefrigCopyModal };
