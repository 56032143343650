import React from 'react';
import {
  ColumnProps,
  ContainerDimensions,
  icons,
  Spinner,
  SpinnerSize,
  Table,
} from '@danfoss/etui-core';
import { Div } from '@danfoss/etui-system-elements';
import {
  getLanguageCodeByName,
  sortStringAlphabetically,
  useAuth,
  EmptyState,
} from '@danfoss/etui-sm';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import {
  DEVICE_STATUS,
  DEVICE_TYPE,
  DiscoveryTableData,
} from '../../types/DiscoveryPage.types';
import { useRefrigLayout } from '../../context';
import { DeviceFlowStatusCntIcon } from '.';

export interface DiscoveryFlowTableProps {
  tableDataLoading: boolean;
}
export const DiscoveryFlowTable = ({
  tableDataLoading,
}: DiscoveryFlowTableProps) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const theme = useTheme();
  const {
    discoveryTableData,
    isOfflineProgramming,
    filteredTableData,
    isTableRefreshing,
    isHvac,
  } = useRefrigLayout();

  const langCode = getLanguageCodeByName(user?.language || 'English') || 'en';

  const getIconBasedOnStatus = (status: string, isLastRow: boolean) => {
    if (status === DEVICE_STATUS.GREEN) {
      return (
        <DeviceFlowStatusCntIcon
          colorTheme={theme.palette.success.main}
          iconGlyph={icons.CHECK_CIRCLE}
          message={t('t3366')}
          isLastRow={isLastRow}
        />
      );
    }
    if (status === DEVICE_STATUS.ORANGE) {
      return (
        <DeviceFlowStatusCntIcon
          colorTheme={theme.palette.warning.main}
          iconGlyph={icons.WARNING}
          message={t('t517')}
          isLastRow={isLastRow}
        />
      );
    }
    return (
      <DeviceFlowStatusCntIcon
        colorTheme={theme.palette.error.main}
        iconGlyph={icons.ERROR_CIRCLE}
        message={t('t3367')}
        isLastRow={isLastRow}
      />
    );
  };
  const checkIfLastRow = (index: number): boolean => {
    return tableData.length - 1 === index;
  };
  const hvacColumns: ColumnProps<DiscoveryTableData>[] = [
    {
      title: t('t42').toUpperCase(),
      dataIndex: 'status',
      sorter: (a: { status: string }, b: { status: string }) =>
        sortStringAlphabetically(a.status, b.status, langCode),
      render: (_, device: DiscoveryTableData, index: number) => {
        const isLastRow: boolean = checkIfLastRow(index);
        return getIconBasedOnStatus(device.status, isLastRow);
      },
    },
    {
      title: t('t76').toUpperCase(),
      dataIndex: 'name',
      sorter: (a: { name: string }, b: { name: string }) =>
        sortStringAlphabetically(a.name, b.name, langCode),
    },
    {
      title: t('t57').toUpperCase(),
      dataIndex: 'address',
      sorter: (a: { address: string }, b: { address: string }) =>
        sortStringAlphabetically(a.address, b.address, langCode),
    },
    {
      title: t('t355').toUpperCase(),
      dataIndex: 'model',
      sorter: (a: { model: string }, b: { model: string }) =>
        sortStringAlphabetically(a.model, b.model, langCode),
    },
    {
      title: t('t500').toUpperCase(),
      dataIndex: 'code',
      sorter: (a: { code: string }, b: { code: string }) =>
        sortStringAlphabetically(a.code, b.code, langCode),
    },
    {
      title: t('t311').toUpperCase(),
      dataIndex: 'version',
      sorter: (a: { version: string }, b: { version: string }) =>
        sortStringAlphabetically(a.version, b.version, langCode),
    },
    {
      title: t('t514').toUpperCase(),
      dataIndex: 'deviceBus',
      sorter: (a: { deviceBus: string }, b: { deviceBus: string }) =>
        sortStringAlphabetically(a.deviceBus, b.deviceBus, langCode),
    },
  ];

  const columns: ColumnProps<DiscoveryTableData>[] = [
    {
      title: t('t42').toUpperCase(),
      dataIndex: 'status',
      sorter: (a: { status: string }, b: { status: string }) =>
        sortStringAlphabetically(a.status, b.status, langCode),
      render: (_, device: DiscoveryTableData, index: number) => {
        const isLastRow: boolean = checkIfLastRow(index);
        return getIconBasedOnStatus(device.status, isLastRow);
      },
    },
    {
      title: t('t76').toUpperCase(),
      dataIndex: 'name',
      sorter: (a: { name: string }, b: { name: string }) =>
        sortStringAlphabetically(a.name, b.name, langCode),
    },
    {
      title: t('t57').toUpperCase(),
      dataIndex: 'address',
      sorter: (a: { address: string }, b: { address: string }) =>
        sortStringAlphabetically(a.address, b.address, langCode),
    },
    {
      title: t('t355').toUpperCase(),
      dataIndex: 'model',
      sorter: (a: { model: string }, b: { model: string }) =>
        sortStringAlphabetically(a.model, b.model, langCode),
    },
    {
      title: t('t34').toUpperCase(),
      dataIndex: 'type',
      sorter: (a: { type: string }, b: { type: string }) =>
        sortStringAlphabetically(a.type, b.type, langCode),
    },
    {
      title: t('t500').toUpperCase(),
      dataIndex: 'code',
      sorter: (a: { code: string }, b: { code: string }) =>
        sortStringAlphabetically(a.code, b.code, langCode),
    },
    {
      title: t('t311').toUpperCase(),
      dataIndex: 'version',
      sorter: (a: { version: string }, b: { version: string }) =>
        sortStringAlphabetically(a.version, b.version, langCode),
    },
    {
      title: t('t514').toUpperCase(),
      dataIndex: 'deviceBus',
      sorter: (a: { deviceBus: string }, b: { deviceBus: string }) =>
        sortStringAlphabetically(a.deviceBus, b.deviceBus, langCode),
    },
  ];

  const loadTableData = (data: DiscoveryTableData[]) => {
    return data.filter(
      data =>
        data?.deviceType !== DEVICE_TYPE.GEN_SUCT &&
        data?.deviceType !== DEVICE_TYPE.NO_COMP_SUCT,
    );
  };

  const tableData: DiscoveryTableData[] = loadTableData(
    isOfflineProgramming ? discoveryTableData : filteredTableData,
  );

  return (
    <ContainerDimensions>
      {({ height }) =>
        tableDataLoading || isTableRefreshing ? (
          <Div
            testId="discovery-flow-table-spinner-div"
            style={{ marginTop: '200px' }}
          >
            <Spinner size={SpinnerSize.small} />
          </Div>
        ) : !discoveryTableData?.length ||
          (!isOfflineProgramming && !filteredTableData?.length) ? (
          <Div
            testId="discovery-flow-table-empty-div"
            style={{ marginTop: '200px' }}
          >
            <EmptyState title={t('t3351')} />
          </Div>
        ) : (
          <Div
            height={height - 200}
            testId="discovery-flow-table-content-div"
            style={{ marginTop: '20px' }}
          >
            <Table
              rowKey="discovery-table-content"
              columns={isHvac ? hvacColumns : columns}
              dataSource={loadTableData(
                isOfflineProgramming ? discoveryTableData : filteredTableData,
              )}
              pagination={false}
              emptyPlaceholder={null}
              styles={{
                td: { p: `${theme.spacing.sm}px` },
                root: { overflow: 'visible' },
              }}
            />
          </Div>
        )
      }
    </ContainerDimensions>
  );
};
