import React from 'react';
import {
  SearchInput,
  SelectInput,
  SelectInputOption,
  SpinnerSize,
  Table,
  Toggle,
} from '@danfoss/etui-core';
import { ContentSpinner, EmptyState } from '@danfoss/etui-sm';
import { Div, Span } from '@danfoss/etui-system-elements';
import { useTheme } from '@danfoss/etui-system';
import { useTranslation } from 'react-i18next';
import { useRefrigCopy } from '../../context';
import {
  SourceAlarmTableContent,
  SourceAlarmTable,
  SourceAlarmTableData,
} from '../../types/RefrigCopy.types';
import { generateNumberArray } from '../../../RefrigLayoutModal/utils';
import { delay } from '../../../../../Configuration/utils';
import { ConfigurationListitemEditInputAddon } from '../../../../../Configuration/components/ConfigurationItemContentList/ConfigurationListItemEditInputAddon';
import { setActionStatus, setLevelStatus } from './actions';

export interface SourceAlarmFlowTableProps {
  handleOnLevelChange: (
    selectedOption: SelectInputOption,
    content: SourceAlarmTableContent,
    selectRowIndex: number,
  ) => void;
  handleOnActionChange: (
    selectedOption: SelectInputOption,
    content: SourceAlarmTableContent,
    selectRowIndex: number,
  ) => void;
  handleOnEnableToggle: (isChecked: boolean) => void;
}

export const SourceAlarmFlowTable = ({
  handleOnLevelChange,
  handleOnActionChange,
  handleOnEnableToggle,
}: SourceAlarmFlowTableProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const {
    sourceAlarmTableData,
    multipage,
    searchMode,
    isAllPageDataFetched,
    isPageDataFetched,
    saveCompleted,
    selectedRowIndex,
    enableAllAlarms,
    setPage,
    setPageValues,
    onSetSearchValue,
    setSaveCompleted,
    setEnableAllAlarms,
  } = useRefrigCopy();

  const [sourceAlarmTable, setSourceAlarmTable] = React.useState<
    SourceAlarmTable[]
  >([]);

  const [sourceAlarmTablePage, setSourceAlarmTablePage] =
    React.useState<SelectInputOption[]>();

  const [sourceAlarmTableSelectedPage, setSourceAlarmTableSelectedPage] =
    React.useState<SelectInputOption>({
      value: '1',
      label: '1',
    });

  React.useEffect(() => {
    if (sourceAlarmTableData?.sourceAlarmTableContent?.length) {
      getTableData(sourceAlarmTableData);
      if (!searchMode) {
        getSourceAlarmPages();
      }
    }
  }, [sourceAlarmTableData]);

  React.useEffect(() => {
    const stopAnimation = async () => {
      await delay(500);
      setLevelStatus(
        sourceAlarmTableData,
        selectedRowIndex,
        false,
        false,
        false,
      );
      setActionStatus(
        sourceAlarmTableData,
        selectedRowIndex,
        false,
        false,
        false,
      );
      getTableData(sourceAlarmTableData);
    };
    if (saveCompleted) {
      stopAnimation();
    }
  }, [saveCompleted]);

  const getSourceAlarmPages = () => {
    const pageValues: number[] = generateNumberArray(1, +multipage);
    const sourceAlarmPageOptions: SelectInputOption[] = [];
    pageValues?.forEach(page => {
      sourceAlarmPageOptions.push({
        value: page.toString(),
        label: page.toString(),
      });
    });
    setPageValues(pageValues);
    setSourceAlarmTablePage(sourceAlarmPageOptions);
  };

  const handleOnPageChange = (options: SelectInputOption) => {
    const page: number = +options.label - 1;
    setSourceAlarmTableSelectedPage(options);
    setPage(page.toString());
  };

  const columns = [
    {
      title: t('t3407').toUpperCase(),
      dataIndex: 'name',
      key: 'name',
      width: '10%',
    },
    {
      title: t('t304').toUpperCase(),
      dataIndex: 'level',
      key: 'level',
      width: '15%',
    },
    {
      title: t('t599').toUpperCase(),
      dataIndex: 'action',
      key: 'action',
      width: '45%',
    },
  ];

  const alarmActionOptions: SelectInputOption[] = generateNumberArray(0, 8).map(
    value => ({
      value: value.toString(),
      label: value.toString(),
    }),
  );
  const getTableData = (sourceAlarmTableData: SourceAlarmTableData) => {
    const sourceAlarmTableDataList: SourceAlarmTable[] = [];
    sourceAlarmTableData?.sourceAlarmTableContent?.forEach((content, index) => {
      if (
        content?.name.trim() !== t('t3408') &&
        content?.name.trim() !== t('t3409')
      ) {
        sourceAlarmTableDataList.push({
          name: content.name,
          level: (
            <Div
              testId="source-alarm-flow-level-div"
              width="80%"
              mt="-10px"
              mb="-10px"
            >
              <SelectInput
                name=""
                value={{ label: content.level, value: '' }}
                options={content.levelList}
                size="small"
                onChange={(e: SelectInputOption) => {
                  setSaveCompleted(false);
                  handleOnLevelChange(e, content, index);
                }}
                disabled={
                  content?.isLevelSubmitting ||
                  content?.isLevelSucceeded ||
                  content?.isLevelFailed
                }
                endInputAddon={
                  content?.isLevelSubmitting ||
                  content?.isLevelSucceeded ||
                  content?.isLevelFailed ? (
                    <ConfigurationListitemEditInputAddon
                      isSubmitting={content?.isLevelSubmitting}
                      isSucceeded={content?.isLevelSucceeded}
                      isFailed={content?.isLevelFailed}
                      styles={{
                        inputAddon: {
                          root: content?.isLevelSubmitting
                            ? {
                                top: '9px',
                                width: '24px',
                                height: '24px',
                                right: `${theme.spacing.xxs}px`,
                              }
                            : {
                                top: '5px',
                                width: '32px',
                                height: '32px',
                                right: `${theme.spacing.xxs}px`,
                              },
                        },
                      }}
                    />
                  ) : null
                }
              />
            </Div>
          ),
          action: (
            <Div
              testId="source-alarm-flow-level-div"
              width="10%"
              mt="-10px"
              mb="-10px"
            >
              {(content.level === t('t3410') ||
                content.level === t('t3411') ||
                content.level === t('t3412')) &&
              content.action ? (
                <SelectInput
                  name=""
                  value={{ label: content.action, value: '' }}
                  options={alarmActionOptions}
                  size="small"
                  styles={{ menuList: { maxHeight: '200px' } }}
                  onChange={(e: SelectInputOption) => {
                    setSaveCompleted(false);
                    handleOnActionChange(e, content, index);
                  }}
                  disabled={
                    content?.isActionSubmitting ||
                    content?.isActionSucceeded ||
                    content?.isActionFailed
                  }
                  endInputAddon={
                    content?.isActionSubmitting ||
                    content?.isActionSucceeded ||
                    content?.isActionFailed ? (
                      <ConfigurationListitemEditInputAddon
                        isSubmitting={content?.isActionSubmitting}
                        isSucceeded={content?.isActionSucceeded}
                        isFailed={content?.isActionFailed}
                        styles={{
                          inputAddon: {
                            root: content?.isActionSubmitting
                              ? {
                                  top: '9px',
                                  width: '24px',
                                  height: '24px',
                                  right: `${theme.spacing.xxs}px`,
                                }
                              : {
                                  top: '5px',
                                  width: '32px',
                                  height: '32px',
                                  right: `${theme.spacing.xxs}px`,
                                },
                          },
                        }}
                      />
                    ) : null
                  }
                />
              ) : null}
            </Div>
          ),
        });
      }
    });
    setSourceAlarmTable(sourceAlarmTableDataList);
    setSaveCompleted(false);
  };

  return (
    <>
      <Div
        style={{
          display: 'flex',
        }}
      >
        <Span
          style={{
            marginLeft: '25px',
            marginTop: '15px',
          }}
        >
          {t('t3413')}
        </Span>
        <Toggle
          styles={{ root: { mt: '15px', ml: '10px' } }}
          name="Enable/Dissable all alarms"
          onChange={(_, isChecked) => {
            setEnableAllAlarms(isChecked);
            handleOnEnableToggle(isChecked);
          }}
          isChecked={enableAllAlarms}
        />
      </Div>
      <Div
        testId="source-alarm-flow-table-search-page-div"
        style={{
          marginTop: '20px',
          width: '60%',
          display: 'inline-block',
        }}
      >
        <Span
          style={{
            width: '30%',
            display: 'inline-block',
          }}
        >
          <SearchInput
            placeholder={t('t3414')}
            testId="config-sourceAlarm-searchInput"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onSetSearchValue(e.target.value)
            }
            styles={{
              input: {
                borderColor: 'grey',
                background: 'white',
              },
            }}
          />
        </Span>
        {!searchMode && multipage ? (
          <Span
            style={{
              width: '30%',
              marginLeft: '10px',
              display: 'inline-block',
            }}
          >
            <SelectInput
              name=""
              options={sourceAlarmTablePage}
              value={sourceAlarmTableSelectedPage}
              onChange={handleOnPageChange}
              size="small"
            />
          </Span>
        ) : null}
      </Div>
      {!isPageDataFetched || (searchMode && !isAllPageDataFetched) ? (
        <Div
          testId="source-alarm-flow-table-spinner-div"
          style={{ marginTop: '200px' }}
        >
          <ContentSpinner size={SpinnerSize.small} transparent={true} />
        </Div>
      ) : !sourceAlarmTableData?.sourceAlarmTableContent.length ? (
        <Div
          testId="source-alarm-flow-table-empty-div"
          style={{ marginTop: '200px' }}
        >
          <EmptyState title={t('t3402')} />
        </Div>
      ) : (
        <>
          <Div
            testId="source-alarm-flow-table-content-div"
            style={{ marginTop: '20px' }}
          >
            <Table
              rowKey="alarm-table-content"
              columns={columns}
              dataSource={sourceAlarmTable}
              pagination={false}
              emptyPlaceholder={null}
              styles={{
                td: { p: `${theme.spacing.sm}px`, height: 10 },
                root: { overflow: 'visible' },
              }}
            />
          </Div>
        </>
      )}
    </>
  );
};
