import * as React from 'react';
import { Button } from '@danfoss/etui-core';
import { Div } from '@danfoss/etui-system-elements';
import { useTranslation } from 'react-i18next';

export interface ConfigurationListItemEditStagingPatternButtonsProps {
  onClose: () => void;
  handleOnOkClick: () => void;
}

export const ConfigurationListItemEditStagingPatternButtons = ({
  onClose,
  handleOnOkClick,
}: ConfigurationListItemEditStagingPatternButtonsProps) => {
  const { t } = useTranslation();
  return (
    <Div float="right" mt="-50px">
      <Button
        styles={{
          root: {
            mr: 10,
            mt: 50,
            width: '120px',
            height: '40px',
          },
        }}
        variant="secondary"
        testId="staging-pattern-close-buttons-div"
        onClick={onClose}
      >
        {t('t45')}
      </Button>
      <Button
        styles={{
          root: {
            mr: 10,
            mt: 50,
            width: '120px',
            height: '40px',
          },
        }}
        variant="primary"
        testId="staging-pattern-ok-buttons-div"
        onClick={() => {
          handleOnOkClick();
          onClose();
        }}
      >
        {t('t44')}
      </Button>
    </Div>
  );
};
